import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { format, subDays } from "date-fns";

import FormPrompt from "../DailogBoxes/formprompt";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import ConfirmDialogBox from "../DailogBoxes/confirmdailogbox";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import Service from "../../Service/firebase";
import { settodaybooking } from "../../actions/settodaybookingaction";
import { searchTextRegex } from "../../constant/regex";

import "./todaybookinglist.css";
const TodayBookingList = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [isCloseBtnAppear, setIsCloseBtnAppear] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalNumOfTodayBooking, setTotalNumOfTodayBooking] = useState(null);
  const [noMoreDataText, setNoMoreDataText] = useState("");
  const [openFormDailog, setOpenFormDailog] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
  const [TodayBookingList, setTodayBookingList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDataShow, setIsSearchDataShow] = useState(false);
  const [salonTypeList, setSalonTypeList] = useState([]);
  const [selectedTodayBookingName, setSelectedTodayBookingName] = useState("");
  const [selectedTodayBookingId, setSelectedTodayBookingId] = useState("");
  const [salonTypeName, setSalonTypeName] = useState("");
  const [sName, setSName] = useState("");
  const [salonName, setSalonName] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(
    format(subDays(new Date(), 7), "yyyy-MM-dd")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [filterStatus, setFilterStatus] = useState("All");
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onSetTotalNumOfTodayBooking();
      onFilterByDates(limit);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const showMore = () => {
    if (limit <= totalNumOfTodayBooking) {
      const limitNew = limit + 10;
      setLimit(limitNew);
      onFilterByDates(limitNew);
    } else {
      setNoMoreDataText("No More Today Booking");
    }
  };
  const onSetTotalNumOfTodayBooking = async () => {
    setIsLoading(true);
    const res = await Service.getTotalNumOfPerson("appointments");
    if (res === "error") {
      console.log("error");
      setIsLoading(false);
    } else {
      setTotalNumOfTodayBooking(res);
      setIsLoading(false);
    }
  };

  const onFilterByDates = async (limit) => {
    setIsLoadMoreData(true);
    const fetchedDataList = await Service.getBookingsDataWithinDateRange(
      new Date(filterStartDate),
      new Date(filterEndDate),
      filterStatus,
      limit
    );

    if (fetchedDataList.length !== 0) {
      setTodayBookingList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setTodayBookingList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const handleOnDelete = (TodayBookingname, id) => {
    setSelectedTodayBookingName(TodayBookingname);
    setSelectedTodayBookingId(id);
    setOpenConfirmDailog(true);
  };
  const deleteData = async () => {
    setIsDeleting(true);
    const res = await Service.deleteData(
      "booking_master",
      selectedTodayBookingId
    );
    if (res === "success") {
      setIsDeleting(false);
      setOpenConfirmDailog(false);
      window.location.reload(false);
    } else {
      setIsDeleting(false);
      handleErrorDailog();
    }
  };
  const handleSearch = async () => {
    if (searchText === "" || null) {
      window.location.reload(false);
    } else {
      setIsSearching(true);
      setIsSearchDataShow(true);

      const searchTextNew = searchText
        .toLowerCase()
        .replace(searchTextRegex, "");

      const resultTodayBookingList = await Service.getSearchRes(
        "booking_master",
        searchTextNew
      );
      if (resultTodayBookingList === "error") {
        setIsSearching(false);
        setOpenErrorDailog(true);
      } else {
        setTodayBookingList(resultTodayBookingList);
        setIsSearching(false);
      }
    }
  };

  const handleSuccessDailog = () => {
    setOpenFormDailog(false);
    setOpenAlertDailog(true);
  };
  const handleErrorDailog = () => {
    setOpenFormDailog(false);
    setOpenConfirmDailog(false);
    setOpenErrorDailog(true);
  };
  const closeFormDailog = () => {
    setOpenFormDailog(false);
  };
  const closeAlertDailog = () => {
    setOpenAlertDailog(false);
    window.location.reload(false);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const closeConfirmDailog = () => {
    setOpenConfirmDailog(false);
  };
  const setCloseBtnAppear = () => {
    setIsCloseBtnAppear(false);
  };

  let count = 0;
  let Salon_name = salonName;

  var data;
  return isLoading ? (
    <div className="todaybookinglistpage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="todaybookinglistpage">
      <div className="main_section">
        <ConfirmDialogBox
          openDailog={openConfirmDailog}
          onSetOpenDailog={closeConfirmDailog}
          handleConfirmOkBtn={deleteData}
          isLoading={isDeleting}
          title="Delete"
          des={
            "Are you sure to delete " +
            selectedTodayBookingName +
            " " +
            "details"
          }
        ></ConfirmDialogBox>
        <AlertDialogBox
          openDailog={openAlertDailog}
          onSetOpenDailog={closeAlertDailog}
          title="Added"
          des="New TodayBooking has been added successfully"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={openErrorDailog}
          onSetOpenDailog={closeErrorDailog}
          title="Error"
          des="Someting went wrong. Try again"
        ></ErorrDialogBox>

        <FormPrompt
          openDailog={openFormDailog}
          title="Add New TodayBooking"
          onSetOpenDailog={closeFormDailog}
          isCloseBtnAppear={isCloseBtnAppear}
        ></FormPrompt>

        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>Bookings</h5>
            </li>
          </ul>
        </div>
        <hr />

        {/*  filter for date range */}
        <div className="my-2 container d-flex flex-row-reverse">
          <form noValidate>
            <TextField
              id="date"
              label="Start date"
              type="date"
              className={"textField"}
              InputLabelProps={{
                shrink: true,
              }}
              value={filterStartDate}
              onChange={async (props) => {
                await setFilterStartDate(props.target.value);

                onFilterByDates(limit);
              }}
            />
            <TextField
              id="date"
              label="End date"
              type="date"
              className={"textField"}
              InputLabelProps={{
                shrink: true,
              }}
              value={filterEndDate}
              onChange={async (props) => {
                await setFilterEndDate(props.target.value);
                onFilterByDates(limit);
              }}
            />
            <FormControl className="mr-3">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterStatus}
                onChange={async (props) => {
                  await setFilterStatus(props.target.value);
                  onFilterByDates(limit);
                }}
              >
                <MenuItem value={"All"}>All Status</MenuItem>
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <table className="table table-striped">
          <thead className="thead tablehead">
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">User Image</th> */}
              <th scope="col">User Name</th>
              <th scope="col">Salon Name</th>
              <th scope="col">Salon Address</th>
              {/* TODO: discuss about salon and user image removal. add amu other fields. change position in table details.*/}
              <th scope="col">Salon Image</th>
              <th scope="col">Schedule Date</th>
              <th scope="col">Schedule Time</th>
              <th scope="col">Total Price</th>

              <th scope="col">Status</th>
              {/* <th scope="col">Option</th> */}
            </tr>
          </thead>
          {isSearching ? (
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
          ) : TodayBookingList.length === 0 ? (
            <tbody>
              <tr>
                <td>No TodayBooking Found</td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tablebody">
              {TodayBookingList &&
                TodayBookingList.map((p) => {
                  count++;
                  // let date = new Date(p.timeStamp.toDate());
                  // const createdTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                  // const createdDate = `${date.getDate()}/${
                  //   date.getMonth() + 1
                  // }/${date.getFullYear()}`;

                  console.log("====================================");
                  console.log(p);
                  console.log("====================================");

                  return (
                    <tr key={p.bookingId}>
                      <td className="align-middle">{count}</td>
                      {/* <td className="align-middle">
                          {p.userImage === "" ? (
                            <div className="userbg">
                              <i className="fa fa-user fa-2x"></i>
                            </div>
                          ) : (
                            <img
                              className="image"
                              alt=""
                              srcSet={p.userImage}
                            />
                          )}
                        </td> */}
                      <td className="align-middle">{p.userName}</td>
                      <td className="align-middle">{p.salonName}</td>
                      <td className="align-middle">{p.salonAddress}</td>
                      <td className="align-middle">
                        {p.salonImage === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img className="image" alt="" srcSet={p.salonImage} />
                        )}
                      </td>
                      <td className="align-middle">
                        {format(new Date(p.scheduleDate), "do, MMM yyyy")}
                      </td>
                      <td className="align-middle">{p.scheduleTime}</td>
                      <td className="align-middle">{p.servicePrice}</td>

                      <td className="align-middle">{p.status}</td>

                      {/* <td className="align-middle">
                        <Link to="editTodayBooking">
                            <button
                              onClick={async () => {
                                const sendData = {
                                  ...p,
                                  collectionName: "TodayBooking",
                                  TodayBookingId: p.TodayBookingid,
                                };

                                props.setOnTodayBooking(sendData);
                              }}
                              type="button"
                              className="btn btn-success"
                            >
                              <i className="fa fa-edit" aria-hidden="true"></i>
                            </button>
                          </Link>
                          
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              console.log(p.TodayBookingid);
                              handleOnDelete(
                                p.name,
                                p.TodayBookingid
                              );
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </td> */}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <div className="loadmoredatasection">
          {isLoadMoreData ? (
            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
          ) : (
            <div className="nomoredatatext">{noMoreDataText}</div>
          )}
          {TodayBookingList.length === 0 ? null : isSearchDataShow ? null : (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => showMore()}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnTodayBooking: (p) => {
      dispatch(settodaybooking(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(TodayBookingList);
