import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import FormPrompt from "../DailogBoxes/formprompt";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import ConfirmDialogBox from "../DailogBoxes/confirmdailogbox";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import Service from "../../Service/firebase";
import AddSubCategory from "./addsubcategory";
import { setsubcategory } from "../../actions/setsubcategoryaction";
import { searchTextRegex } from "../../constant/regex";

import "./subcategorylist.css";

const SubCategoryList = (props) => {
  const [searchText, setsSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [isCloseBtnAppear, setIsCloseBtnAppear] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalNumOfSubCategory, setTotalNumOfSubCategory] = useState(null);
  const [noMoreDataText, setNoMoreDataText] = useState("");
  const [openFormDailog, setOpenFormDailog] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDataShow, setIsSearchDataShow] = useState(false);
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [salonTypeList, setSalonTypeList] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onSetTotalNumOfSubCategory();
      onFetchData(limit);
      onFetchSalonType();
      onFetchCategory(limit);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const showMore = () => {
    if (limit <= totalNumOfSubCategory) {
      const limit = limit + 10;
      setLimit(limit);
      onFetchData(limit);
    } else {
      setNoMoreDataText("No MoreCategory");
    }
  };
  const onSetTotalNumOfSubCategory = async () => {
    setIsLoading(true);
    const res = await Service.getTotalNumOfPerson("subCategory");
    if (res === "error") {
      console.log("error");
      setIsLoading(false);
    } else {
      setTotalNumOfSubCategory(res);
      setIsLoading(false);
    }
  };

  const onFetchData = async (limit) => {
    setIsLoadMoreData(true);

    const fetchedDataList = await Service.getData("subCategory", limit);

    if (fetchedDataList.length !== 0) {
      setSubCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSubCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
    }
  };
  const onFetchCategory = async (limit) => {
    setIsLoadMoreData(true);

    const fetchedDataList = await Service.getData("category", limit);

    if (fetchedDataList.length !== 0) {
      setCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const getIdByName = async (docID) => {
    // setState({ isLoadMoreData: true });
    console.log("fetch name" + docID);
    const fetchSalonName = await Service.getIdByName("category", docID);
    //  setState({
    //   Salon_name: fetchSalonName,

    // });
    // setState({ isLoadMoreData: false });
    // return fetchSalonName;
    // console.log("fetch name");
    // console.log(fetchSalonName);
    // if (fetchSalonName.length !== 0) {
    //   setState({
    //     Salon_name: fetchSalonName,
    //     isLoadMoreData: false,
    //     isLoading: false,
    //   });
    // } else {
    //   setState({
    //     Salon_name: fetchSalonName,
    //     isLoadMoreData: false,
    //     isLoading: false,
    //   });
    // }
    return fetchSalonName;
  };
  const onFetchSalonType = async () => {
    setIsLoadMoreData(true);

    const fetchedSalonDataList = await Service.getData("category");

    if (fetchedSalonDataList.length !== 0) {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const handleOnDelete = (CategoryName, id) => {
    setSelectedSubCategoryName(CategoryName);
    setSelectedSubCategoryId(id);
    setOpenConfirmDailog(true);
  };
  const deleteData = async () => {
    setIsDeleting(true);
    const res = await Service.deleteData("subcategory", selectedSubCategoryId);
    if (res === "success") {
      setIsDeleting(false);
      setOpenConfirmDailog(false);
      window.location.reload(false);
    } else {
      setIsDeleting(false);
      handleErrorDailog();
    }
  };
  const handleSearch = async () => {
    if (searchText === "" || null) {
      window.location.reload(false);
    } else {
      setIsSearching(true);
      setIsSearchDataShow(true);

      const searchTextWithRegex = searchText
        ?.toLowerCase()
        .replace(searchTextRegex, "");

      const resultSubCategoryList = await Service.getSearchRes(
        "subcategory",
        searchTextWithRegex
      );
      if (resultSubCategoryList === "error") {
        setIsSearching(false);
        setOpenErrorDailog(true);
      } else {
        setSubCategoryList(resultSubCategoryList);
        setIsSearching(false);
      }
    }
  };

  const handleSuccessDailog = () => {
    setOpenFormDailog(false);
    setOpenAlertDailog(true);
  };
  const handleErrorDailog = () => {
    setOpenFormDailog(false);
    setOpenConfirmDailog(false);
    setOpenErrorDailog(true);
  };
  const closeFormDailog = () => {
    setOpenFormDailog(false);
  };
  const closeAlertDailog = () => {
    setOpenAlertDailog(false);
    window.location.reload(false);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const closeConfirmDailog = () => {
    setOpenConfirmDailog(false);
  };
  const setCloseBtnAppear = () => {
    setCloseBtnAppear(false);
  };

  let count = 0;

  return isLoading ? (
    <div className="subcategorylistpage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="subcategorylistpage">
      <div className="main_section">
        <ConfirmDialogBox
          openDailog={openConfirmDailog}
          onSetOpenDailog={closeConfirmDailog}
          handleConfirmOkBtn={deleteData}
          isLoading={isDeleting}
          title="Delete"
          des={
            "Are you sure to delete " +
            selectedSubCategoryName +
            " " +
            "details"
          }
        ></ConfirmDialogBox>
        <AlertDialogBox
          openDailog={openAlertDailog}
          onSetOpenDailog={closeAlertDailog}
          title="Added"
          des="New Category has been added successfully"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={openErrorDailog}
          onSetOpenDailog={closeErrorDailog}
          title="Error"
          des="Something went wrong. Try again"
        ></ErorrDialogBox>

        <FormPrompt
          openDailog={openFormDailog}
          title="Add New SubCategory"
          onSetOpenDailog={closeFormDailog}
          isCloseBtnAppear={isCloseBtnAppear}
        >
          <AddSubCategory
            setCloseBtnAppear={setCloseBtnAppear}
            handleSuccessDailog={handleSuccessDailog}
            handleErrorDailog={handleErrorDailog}
            collectionName="subcategory"
            id="subcategoryid"
          ></AddSubCategory>
        </FormPrompt>

        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>SubCategory</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="top_section">
          <div className="wrap">
            <ul>
              <li>
                <div className="search">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="Search SubCategory by  name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    onChange={(e) => {
                      setsSearchText(e.target.value);
                    }}
                  />

                  <button
                    onClick={handleSearch}
                    type="submit"
                    className="searchButton"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </li>
              <li style={{ fontSize: "12px" }}> #</li>
              <li style={{ fontSize: "12px" }}>{SubCategoryList.length} </li>
            </ul>
          </div>

          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              setOpenFormDailog(true);
            }}
          >
            + Add SubCategory
          </button>
        </div>
        <table className="table table-striped">
          <thead className="thead tablehead">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Icon</th>
              <th scope="col">Name</th>
              <th scope="col">Category Name</th>
              {/* <th scope="col">Option</th> */}
            </tr>
          </thead>
          {isSearching ? (
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
          ) : SubCategoryList.length === 0 ? (
            <tbody>
              <tr>
                <td>No SubCategory Found</td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tablebody">
              {SubCategoryList &&
                SubCategoryList.map((p) => {
                  count++;
                  // let date = new Date(p.timeStamp.toDate());
                  // const createdTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                  // const createdDate = `${date.getDate()}/${
                  //   date.getMonth() + 1
                  // }/${date.getFullYear()}`;
                  const Salon_name = getIdByName(p.categoryid);
                  return (
                    <tr key={p.subCategoryId}>
                      <td className="align-middle">{count}</td>
                      <td className="align-middle">
                        {p.imgaeurl === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img className="image" alt="" srcSet={p.imgaeurl} />
                        )}
                      </td>
                      <td className="align-middle">{p.name}</td>

                      <td className="align-middle">
                        {categoryList.map((option) =>
                          option.categoryId == p.categoryId ? option.name : null
                        )}
                      </td>

                      {/* <td className="align-middle">
                        <Link to="editsubcategory">
                          <button
                            onClick={async () => {
                              const sendData = {
                                ...p,
                                collectionName: "subCategory",
                                subcategoryId: p.subCategoryId,
                              };

                              props.setOnsubcategory(sendData);
                            }}
                            type="button"
                            className="btn btn-success"
                          >
                            <i className="fa fa-edit" aria-hidden="true"></i>
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            handleOnDelete(p.name, p.subcategoryid);
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <div className="loadmoredatasection">
          {isLoadMoreData ? (
            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
          ) : (
            <div className="nomoredatatext">{noMoreDataText}</div>
          )}
          {SubCategoryList.length === 0 ? null : isSearchDataShow ? null : (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => showMore()}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnsubcategory: (p) => {
      dispatch(setsubcategory(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(SubCategoryList);
