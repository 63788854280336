import React from "react";

import "./newsalontypeform.css";

const NewSalonTypeForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="container">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Name</label>
            <input
              name="name"
              type="text"
              className="form-control"
              id="name"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row second_section">
          <div className="clo-sm-6">
            <div className="profileImage">{props.htmlelement}</div>
          </div>

          <div className="col-sm-6 btn_section">
            <label htmlFor="files" className="selectImage">
              Upload Image
            </label>
            <input
              name="avatarimage"
              id="files"
              type="file"
              onChange={props.onImageChange}
            />
            <button
              type="button"
              className="removebutton"
              onClick={props.onImageRemove}
            >
              {" "}
              Remove
            </button>
          </div>
        </div>
      </div>
      <button className="btn btn-success savebtn" type="submit">
        Save
      </button>
    </form>
  );
};

export default NewSalonTypeForm;
