import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FormPrompt from "../DailogBoxes/formprompt";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import ConfirmDialogBox from "../DailogBoxes/confirmdailogbox";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import Service from "../../Service/firebase";
import AddVendor from "./addvendor";
import { setvendor } from "../../actions/setvendoraction";
import { searchTextRegex } from "../../constant/regex";

import "./vendorlist.css";
const RequestVendorList = (props) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [isCloseBtnAppear, setIsCloseBtnAppear] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalNumOfVendor, setTotalNumOfVendor] = useState(null);
  const [noMoreDataText, setNoMoreDataText] = useState("");
  const [openFormDailog, setOpenFormDailog] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
  const [VendorList, setVendorList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDataShow, setIsSearchDataShow] = useState(false);
  const [selectedVendorName, setSelectedVendorName] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [SalonTypeList, setSalonTypeList] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onSetTotalNumOfVendor();
      onFetchData(limit);
      onFetchSalonType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const showMore = () => {
    if (limit <= totalNumOfVendor) {
      const limitNew = limit + 10;
      setLimit(limitNew);
      onFetchData(limitNew);
    } else {
      setNoMoreDataText("No MoreVendors");
    }
  };
  const onSetTotalNumOfVendor = async () => {
    setIsLoading(true);
    const res = await Service.getTotalNumOfPerson("vendors");
    if (res === "error") {
      console.log("error");

      setIsLoading(false);
    } else {
      setTotalNumOfVendor(res);
      setIsLoading(false);
    }
  };

  const onFetchData = async (limit) => {
    setIsLoadMoreData(true);

    const fetchedDataList = await Service.getData("vendors", limit);

    if (fetchedDataList.length !== 0) {
      setVendorList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setVendorList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };

  const onFetchSalonType = async () => {
    setIsLoadMoreData(true);

    const fetchedSalonDataList = await Service.getData("category");

    if (fetchedSalonDataList.length !== 0) {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const handleOnDelete = (VendorName, id) => {
    setSelectedVendorName(VendorName);
    setSelectedVendorId(id);
    setOpenConfirmDailog(true);
  };
  const deleteData = async () => {
    setIsDeleting(true);
    const res = await Service.deleteData("vendors", selectedVendorId);
    if (res === "success") {
      setIsDeleting(false);
      setOpenConfirmDailog(false);
      window.location.reload(false);
    } else {
      setIsDeleting(false);
      handleErrorDailog();
    }
  };
  const handleSearch = async () => {
    if (searchText === "" || null) {
      window.location.reload(false);
    } else {
      setIsSearching(true);
      setIsSearchDataShow(true);

      const searchText = searchText.toLowerCase().replace(searchTextRegex, "");

      const resultVendorList = await Service.getSearchRes(
        "vendors",
        searchText
      );
      if (resultVendorList === "error") {
        setIsSearching(true);
        setOpenErrorDailog(true);
      } else {
        setIsSearching(false);
        setVendorList(resultVendorList);
      }
    }
  };
  const handleSuccessDailog = () => {
    setOpenFormDailog(false);
    setOpenAlertDailog(true);
  };
  const handleErrorDailog = () => {
    setOpenFormDailog(false);
    setOpenConfirmDailog(false);
    setOpenErrorDailog(true);
  };
  const closeFormDailog = () => {
    setOpenFormDailog(false);
  };
  const closeAlertDailog = () => {
    setOpenAlertDailog(false);
    window.location.reload(false);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const closeConfirmDailog = () => {
    setOpenConfirmDailog(false);
  };
  const setCloseBtnAppear = () => {
    setIsCloseBtnAppear(false);
  };
  let count = 0;
  return isLoading ? (
    <div className="vendorlistpage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="vendorlistpage">
      <div className="main_section">
        <ConfirmDialogBox
          openDailog={openConfirmDailog}
          onSetOpenDailog={closeConfirmDailog}
          handleConfirmOkBtn={deleteData}
          isLoading={isDeleting}
          title="Delete"
          des={"Are you sure to delete " + selectedVendorName + " " + "details"}
        ></ConfirmDialogBox>
        <AlertDialogBox
          openDailog={openAlertDailog}
          onSetOpenDailog={closeAlertDailog}
          title="Added"
          des="New Vendor has been added sccessfully"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={openErrorDailog}
          onSetOpenDailog={closeErrorDailog}
          title="Error"
          des="Someting went wrong. Try again"
        ></ErorrDialogBox>

        <FormPrompt
          openDailog={openFormDailog}
          title="Add New Vendor"
          onSetOpenDailog={closeFormDailog}
          isCloseBtnAppear={isCloseBtnAppear}
        >
          <AddVendor
            setCloseBtnAppear={setCloseBtnAppear}
            handleSuccessDailog={handleSuccessDailog}
            handleErrorDailog={handleErrorDailog}
            collectionName="vendor"
            id="vendorid"
          ></AddVendor>
        </FormPrompt>

        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>Vendor</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="top_section">
          <div className="wrap">
            <ul>
              <li>
                <div className="search">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="Search Vendor by  name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />

                  <button
                    onClick={handleSearch}
                    type="submit"
                    className="searchButton"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </li>
              <li style={{ fontSize: "12px" }}> #</li>
              <li style={{ fontSize: "12px" }}>{VendorList.length} </li>
            </ul>
          </div>

          {/* <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setState({
                  openFormDailog: true,
                });
              }}
            >
              + Add Vendor
            </button> */}
        </div>
        <table className="table table-striped">
          <thead className="thead tablehead">
            <tr>
              <th scope="col">#</th>
              <th scope="col">User Image</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Shop Name</th>
              <th scope="col">Shop Address</th>
              <th scope="col">Shop Image</th>
              <th scope="col">Status</th>
              <th scope="col">Option</th>
            </tr>
          </thead>
          {isSearching ? (
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
          ) : VendorList.length === 0 ? (
            <tbody>
              <tr>
                <td>No Vendor Found</td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tablebody">
              {VendorList &&
                VendorList.map((p) => {
                  count++;
                  let date = new Date(p.timeStamp.toDate());
                  const createdTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                  const createdDate = `${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()}`;

                  return (
                    <tr key={p.vendorid}>
                      <td className="align-middle">{count}</td>
                      <td className="align-middle">
                        {p.imgaeurl === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img className="image" alt="" srcSet={p.imgaeurl} />
                        )}
                      </td>
                      <td className="align-middle">{p.name}</td>

                      <td className="align-middle">{p.email}</td>
                      <td className="align-middle">{p.phone}</td>
                      <td className="align-middle">{p.salon_name}</td>
                      <td className="align-middle">{p.salon_address}</td>
                      <td className="align-middle">
                        {p.salon_image === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img
                            className="image"
                            alt=""
                            srcSet={p.salon_image}
                          />
                        )}
                      </td>
                      <td className="align-middle">
                        {p.status == true ? "Active" : "Inactive"}

                        {/* <div   className="wrg-toggle">
            <div className="wrg-toggle-container">
            {p.status?"true":"false"}
                <div className="wrg-toggle-check">
                    
                </div>
                <div className="wrg-toggle-uncheck">
                    
                </div>
            </div>
            <div className="wrg-toggle-circle"></div>
            <input className="wrg-toggle-input" type="checkbox" aria-label="Toggle Button" />
        </div> */}
                      </td>

                      <td className="align-middle">
                        <Link to="editVendor">
                          <button
                            onClick={async () => {
                              const sendData = {
                                ...p,
                                collectionName: "vendor",
                                vendorId: p.vendorid,
                              };

                              props.setOnVendorDetails(sendData);
                            }}
                            type="button"
                            className="btn btn-success"
                          >
                            <i className="fa fa-edit" aria-hidden="true"></i>
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            console.log(p.vendorid);
                            handleOnDelete(p.name, p.vendorid);
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <div className="loadmoredatasection">
          {isLoadMoreData ? (
            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
          ) : (
            <div className="nomoredatatext">{noMoreDataText}</div>
          )}
          {VendorList.length === 0 ? null : isSearchDataShow ? null : (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => showMore()}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnVendorDetails: (p) => {
      dispatch(setvendor(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(RequestVendorList);
