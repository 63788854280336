import React, { Component } from "react";

import "./newbannerform.css";
import Service from "../../Service/firebase";

class NewBannerForm extends Component {
  
  
  
  render() {
    
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="container">
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label htmlFor="validationDefault01">Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                id="name"
                onChange={this.props.onEdit}
                required
              />
            </div>
           
          </div>
          
         

         

        
        </div>

        <div className="container">
          <div className="row second_section">
            <div className="clo-sm-6">
              <div className="profileimage">{this.props.htmlelement}</div>
            </div>

            <div className="col-sm-6 btn_section">
              <label htmlFor="files" className="selectimage">
                Upload Image
              </label>
              <input
                name="avatarimage"
                id="files"
                type="file"
                onChange={this.props.onImageChange}
              />
              <button
                type="button"
                className="removebutton"
                onClick={this.props.onImageRemove}
              >
                {" "}
                Remove
              </button>
            </div>
          </div>
        </div>
        <button className="btn btn-success savebtn" type="submit">
          Save
        </button>
      </form>
    );
  }
}

export default NewBannerForm;
