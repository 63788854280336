import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setsalontype } from "../../actions/setsalontypeaction";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import Service from "../../Service/firebase";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import EditSalonTypeForm from "../SalonType/editsalontypeform";

import "react-datepicker/dist/react-datepicker.css";

const EditSalonType = (props) => {
  const [date, setDate] = useState(props.salontype.birthdate);
  const [startDate, setStartDate] = useState(
    new Date(props.salontype.birthdate)
  );
  const [image, setImage] = useState("");
  const [profileHtmlElement, setProfileHtmlElement] = useState(
    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [salonType, setSalonType] = useState({
    name: props.salontype.name,
    imgaeurl: props.salontype.imgaeurl,
    collectionName: props.salontype.collectionName,
  });

  const [alertDailogBoxTitle, setAlertDailogBoxTitle] = useState("");
  const [alertDailogBoxDes, setAlertDailogBoxDes] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);

    handleImageForUpload();
  };
  const handleImageForUpload = () => {
    const imageNew = image;
    if (image !== "") {
      onUploadImage(imageNew);
    } else {
      onUpdate();
    }
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      setsalontype({
        ...salonType,
        imgaeurl: url,
      });

      onUpdate();
    }
  };
  const onUpdate = async () => {
    const sendData = {
      ...salonType,
      searchbyname: salonType.name.toLowerCase(),
    };
    const res = await Service.updateData(
      sendData,
      props.salontype.collectionName,
      props.salontype.salonTypeId
    );
    if (res === "success") {
      setIsLoading(false);
      setOpenAlertDailog(true);
      setAlertDailogBoxTitle("Update");
      setAlertDailogBoxDes("successfully updated");

      let salontype = salonType;
      salontype = {
        ...salontype,
        collectionName: props.salontype.collectionName,
        salonTypeId: props.salontype.salonTypeId,
      };

      props.setOnsalontype(salontype);
    } else {
      setIsLoading(false);
      handleErrorDailog();
    }
  };
  const handleErrorDailog = () => {
    setOpenErrorDailog(true);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setSalonType({
        ...salonType,
        birthdate: `${
          birthDate.getMonth() + 1
        }/${birthDate.getDate()}/${birthDate.getFullYear()}`,
      });
      setDate(date);
      setStartDate(date);
    } else {
      setSalonType({
        ...salonType,
        birthdate: date,
      });
      setDate(date);
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setSalonType({
          ...salonType,
          imgaeurl: e.target.result,
        });
        reader.readAsDataURL(event.target.files[0]);
      };
    }
  };
  const onImageRemove = () => {
    setSalonType({
      ...salonType,
      imgaeurl: "",
    });
    setImage("");
  };

  const onEdit = (e) => {
    let salontype = salonType;

    setSalonType({
      ...salonType,
      [e.target.name]: e.target.value,
    });
    console.log(salontype);
  };
  const handleSetOpenDailog = () => {
    // setAlertOpenDailog(false);
    setOpenAlertDailog(false);
  };

  useEffect(() => {
    if (salonType.imgaeurl === "") {
      setProfileHtmlElement(
        <div className="editsalontypepage">
          <i className="fa fa-user fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      setProfileHtmlElement(
        <div className="editsalontypepage">
          <img className="netimage" srcSet={salonType.imgaeurl} alt="" />
        </div>
      );
    }
  }, [salonType.imgaeurl]);

  return isLoading ? (
    <div className="editsalontypepage">
      <i
        className="fas fa-spinner fa-pulse fa-4x"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      ></i>
    </div>
  ) : (
    <div className="editsalontypepage">
      <AlertDialogBox
        openDailog={openAlertDailog}
        setOpenDailog={setOpenAlertDailog}
        onSetOpenDailog={handleSetOpenDailog}
        title="Update"
        des="successfully updated"
      ></AlertDialogBox>
      <ErorrDialogBox
        openDailog={openErrorDailog}
        onSetOpenDailog={closeErrorDailog}
        title="Error"
        des="Something went wrong. Try again"
      ></ErorrDialogBox>
      <EditSalonTypeForm
        handleSubmit={handleSubmit}
        onEdit={onEdit}
        date={date}
        handleChange={handleChange}
        salontype={salonType}
        profileHtmlelEment={profileHtmlElement}
        onImageRemove={onImageRemove}
        onImageChange={onImageChange}
        handleImageForUpload={handleImageForUpload}
      ></EditSalonTypeForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    salontype: state.salontype,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnsalontype: (p) => {
      dispatch(setsalontype(p));
    },
  };
};
export default connect(mapStateToProps, mapDisptachToProps)(EditSalonType);
