import firebase from "firebase";
// import { initializeApp } from "firebase/app";

const config = {
  apiKey: "AIzaSyCzYf0dgtIfO5SKM2TXZstz1m716kxca2g",
  authDomain: "easemysalon-43d6a.firebaseapp.com",
  projectId: "easemysalon-43d6a",
  storageBucket: "easemysalon-43d6a.appspot.com",
  messagingSenderId: "840985874329",
  appId: "1:840985874329:web:a8058ad34e7bab803bcd08",
  measurementId: "G-37R77FWMZB",
};
// const config = {
//   apiKey: "AIzaSyCeQQ6dLL_BPGmylpIa9w306pE2fjdPKFA",
//   authDomain: "vgroomu-2a0e5.firebaseapp.com",
//   projectId: "vgroomu-2a0e5",
//   storageBucket: "vgroomu-2a0e5.appspot.com",
//   messagingSenderId: "1097516798341",
//   appId: "1:1097516798341:web:97fdbc5b2b83486148b4ec",
//   measurementId: "G-YQ81KQZ7X7",
// };

// const db = firebaseApp.firestore();
// const storage = firebaseApp.storage();
// const app = initializeApp(firebaseConfig);
firebase.initializeApp(config);
export default firebase;
