export const categoryReducer = (state = "", action) => {
    switch (action.type) {
      case "SET_CATEGORY_DETAILS":
        return action.payload;
  
      default:
        return state;
    }
  };
  export const reportDetailsReducer = (state = "", action) => {
    switch (action.type) {
      case "SET_REPORT_DETAILS":
        return action.payload;
  
      default:
        return state;
    }
  };
  