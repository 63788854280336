export const setvendor = (p) => {
    return {
      type: "SET_VENDOR_DETAILS",
      payload: p,
    };
  };
  export const setReportDetails = (p) => {
    return {
      type: "SET_REPORT_DETAILS",
      payload: p,
    };
  };
  export const setLogInDetails = (p) => {
    return {
      type: "SET_LOGIN_DETAILS",
      payload: p,
    };
  };
  