import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import FormPrompt from "../DailogBoxes/formprompt";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import ConfirmDialogBox from "../DailogBoxes/confirmdailogbox";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import Service from "../../Service/firebase";
import AddCategory from "./addcategory";
import { setcategory } from "../../actions/setcategoryaction";
import firebase from "../../firebase";

import "./categorylist.css";

const CategoryList = (props) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [isCloseBtnAppear, setIsCloseBtnAppear] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalNumOfCategory, setTotalNumOfCategory] = useState(null);
  const [noMoreDataText, setNoMoreDataText] = useState("");
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
  const [openFormDailog, setOpenFormDailog] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDataShow, setIsSearchDataShow] = useState(false);
  const [salonTypeList, setSalonTypeList] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [salonTypeName, setSalonTypeName] = useState("");
  const [sname, setSname] = useState("");
  const [salonName, setSalonName] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onSetTotalNumOfCategory();
      onFetchData(limit);
      onFetchSalonType(limit);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const showMore = () => {
    if (limit <= totalNumOfCategory) {
      const limit = limit + 10;
      setLimit(limit);
      onFetchData(limit);
    } else {
      setNoMoreDataText("No MoreCategory");
    }
  };
  const onSetTotalNumOfCategory = async () => {
    setIsLoading(true);
    const res = await Service.getTotalNumOfPerson("category");
    if (res === "error") {
      console.log("error");

      setIsLoading(false);
    } else {
      setTotalNumOfCategory(res);
      setIsLoading(false);
    }
  };

  const onFetchData = async (limit) => {
    setIsLoadMoreData(true);

    //   const fetchedDataList = await Service.getData("category", limit);
    const fetchedDataList = await Service.getJoinData(
      "category",
      limit,
      "salonType"
    );
    //salontypename =   getIdByName(fetchedDataList.salontypeid)
    //  fetchedDataList.push(salontypename);
    //     let loadedPosts = {};

    const db = firebase.firestore();

    let newdata;
    var salonName;

    //  for(var i = 0 ; i<fetchedDataList.length; i++){
    console.log("Main array2");
    // fetchedDataList.map((p) => {
    // newdata = getIdByName(p.salontypeid);

    // newdata.then(function(result) {

    // salonName.push(result[0].name);
    //  var joined = salonName.push(result[0].name);
    //setState({ salonName: joined })

    // fetchedDataList.splice(0, 0, {'salonName': result[0].name});

    //   })

    // const obj = { 'salontypename':p.name};
    //      fetchedDataList.push(obj);
    //});
    //console.log(newdata)
    //  console.log(fetchedDataList)
    // }

    console.log("Main array");
    // console.log(fetchedDataList);
    if (fetchedDataList.length !== 0) {
      setCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setCategoryList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const onFetchSalonType = async (limit) => {
    setIsLoadMoreData(true);

    const fetchedDataList = await Service.getData("salonType", limit);

    if (fetchedDataList.length !== 0) {
      setSalonTypeList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalonTypeList(fetchedDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const getIdByName = async (docID) => {
    const fetchSalonName = await Service.getIdByName("salonType", docID);
    // console.log(fetchSalonName)

    return fetchSalonName;
  };
  const handleOnDelete = (CategoryName, id) => {
    setSelectedCategoryName(CategoryName);
    setSelectedCategoryId(id);
    setOpenConfirmDailog(true);
  };
  const deleteData = async () => {
    setIsDeleting(true);
    const res = await Service.deleteData("category", selectedCategoryId);
    if (res === "success") {
      setIsDeleting(false);
      setOpenConfirmDailog(false);
      window.location.reload(false);
    } else {
      setIsDeleting(false);
      handleErrorDailog();
    }
  };
  const handleSearch = async () => {
    if (searchText === "" || null) {
      window.location.reload(false);
    } else {
      setIsSearching(true);
      setIsSearchDataShow(true);

      const searchText = searchText.toLowerCase().replace(/\s/g, "");

      const resultCategoryList = await Service.getSearchRes(
        "category",
        searchText
      );
      if (resultCategoryList === "error") {
        setIsSearching(false);
        setOpenErrorDailog(true);
      } else {
        setCategoryList(resultCategoryList);
        setIsSearching(false);
      }
    }
  };

  const handleSuccessDailog = () => {
    setOpenAlertDailog(true);
    setOpenFormDailog(false);
  };
  const handleErrorDailog = () => {
    setOpenFormDailog(false);
    setOpenConfirmDailog(false);
    setOpenErrorDailog(false);
  };
  const closeFormDailog = () => {
    setOpenFormDailog(false);
  };
  const closeAlertDailog = () => {
    setOpenAlertDailog(false);
    window.location.reload(false);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const closeConfirmDailog = () => {
    setOpenConfirmDailog(false);
  };
  const setCloseBtnAppear = () => {
    setIsCloseBtnAppear(false);
  };

  const fetchSalonName = async (docID) => {
    const fetchSalonName = await Service.getIdByName("salonType", docID);
    // setState({
    //   sname1: fetchSalonName[0].name,
    //    isLoadMoreData: false,
    // isLoading: false,
    // })
    return fetchSalonName;
  };

  let count = 0;
  let Salon_name = salonName;

  var data;
  return isLoading ? (
    <div className="categoryListPage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="categoryListPage">
      <div className="mainSection">
        <ConfirmDialogBox
          openDailog={openConfirmDailog}
          onSetOpenDailog={closeConfirmDailog}
          handleConfirmOkBtn={deleteData}
          isLoading={isDeleting}
          title="Delete"
          des={
            "Are you sure to delete " + selectedCategoryName + " " + "details"
          }
        ></ConfirmDialogBox>
        <AlertDialogBox
          openDailog={openAlertDailog}
          onSetOpenDailog={closeAlertDailog}
          title="Added"
          des="New Category has been added successfully"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={openErrorDailog}
          onSetOpenDailog={closeErrorDailog}
          title="Error"
          des="Something went wrong. Try again"
        ></ErorrDialogBox>

        <FormPrompt
          openDailog={openFormDailog}
          title="Add New Category"
          onSetOpenDailog={closeFormDailog}
          isCloseBtnAppear={isCloseBtnAppear}
        >
          <AddCategory
            setCloseBtnAppear={setCloseBtnAppear}
            handleSuccessDailog={handleSuccessDailog}
            handleErrorDailog={handleErrorDailog}
            collectionName="category"
            id="categoryId"
          ></AddCategory>
        </FormPrompt>

        <div className="topHeader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>Category</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="topSection">
          <div className="wrap">
            <ul>
              <li>
                <div className="search">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="Search Category by  name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />

                  <button
                    onClick={handleSearch}
                    type="submit"
                    className="searchButton"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </li>
              <li style={{ fontSize: "12px" }}> #</li>
              <li style={{ fontSize: "12px" }}>{categoryList.length} </li>
            </ul>
          </div>
          {/* <Link to="addcategory">
                            <button
                             
                              type="button"
                              className="btn btn-warning"
                            >
                             + Add Category
                            </button>
                          </Link> */}
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              setOpenFormDailog(true);
            }}
          >
            + Add Category
          </button>
        </div>
        <table className="table table-striped">
          <thead className="thead tablehead">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Icon</th>
              <th scope="col">Name</th>
              <th scope="col">Salon Type</th>
              {/* <th scope="col">Option</th> */}
            </tr>
          </thead>
          {isSearching ? (
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
          ) : categoryList.length === 0 ? (
            <tbody>
              <tr>
                <td>No Category Found</td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tablebody">
              {categoryList &&
                categoryList.map((p) => {
                  count++;
                  // let date = new Date(p.timeStamp.toDate());
                  // const createdTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                  // const createdDate = `${date.getDate()}/${
                  //   date.getMonth() + 1
                  // }/${date.getFullYear()}`;
                  return (
                    <tr key={p.categoryId}>
                      <td className="align-middle">{count}</td>
                      <td className="align-middle">
                        {p.imageurl === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img className="image" alt="" srcSet={p.imageurl} />
                        )}
                      </td>
                      <td className="align-middle">{p.name}</td>

                      <td className="align-middle">
                        {salonTypeList.map((option) =>
                          option.salonTypeId == p.salonTypeId
                            ? option.name
                            : null
                        )}
                      </td>

                      {/* <td className="align-middle">
                        <Link to="editcategory">
                          <button
                            onClick={async () => {
                              const sendData = {
                                ...p,
                                collectionName: "category",
                                categoryId: p.categoryId,
                              };

                              props.setOnCategory(sendData);
                            }}
                            type="button"
                            className="btn btn-success"
                          >
                            <i className="fa fa-edit" aria-hidden="true"></i>
                          </button>
                        </Link>

                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            console.log(p.categoryid);
                            handleOnDelete(p.name, p.categoryId);
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <div className="loadMoreDataSection">
          {isLoadMoreData ? (
            <i className="fas fa-spinner fa-pulse fa-2x loadMoreDataSpinner"></i>
          ) : (
            <div className="noMoreDataText">{noMoreDataText}</div>
          )}
          {categoryList.length === 0 ? null : isSearchDataShow ? null : (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => showMore()}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnCategory: (p) => {
      dispatch(setcategory(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(CategoryList);
