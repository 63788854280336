import React, { Component } from "react";

import "./edituserform.css";

const EditUserForm = (props) => {
  return (
    <div className="ditd_salon_type_formpage">
      <div className="container main_section">
        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>{props.user.collectionName}</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-8 first_section">
            <form onSubmit={props.handleSubmit}>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.name}
                    onChange={props.onEdit}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Email</label>
                  <input
                    name="email"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.email}
                    onChange={props.onEdit}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Phone</label>
                  <input
                    name="phone"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.phone}
                    onChange={props.onEdit}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Password</label>
                  <input
                    name="password"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.password}
                    onChange={props.onEdit}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Status</label>
                  <input
                    name="status"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.status}
                    onChange={props.onEdit}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Gender</label>
                  <input
                    name="gender"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.user.gender}
                    onChange={props.onEdit}
                  />
                </div>
              </div>

              <button className="btn btn-success update_btn" type="submit">
                Update
              </button>
            </form>
          </div>
          <div className="col-sm-4 second_section">
            <div className="profileimage">{props.profileHtmlelEment}</div>
            <div className="btn_section">
              <label htmlFor="files" className="selectimage">
                Upload Image
              </label>
              <input
                name="avatarimage"
                id="files"
                type="file"
                onChange={props.onImageChange}
              />
              <button
                type="button"
                className="removebutton"
                onClick={props.onImageRemove}
              >
                {" "}
                Remove
              </button>
            </div>

            <input id="files" type="file" onChange={props.onImageChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserForm;
