import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setuser } from "../../actions/setuseraction";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import Service from "../../Service/firebase";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import EditUserForm from "../Users/edituserform";

import "react-datepicker/dist/react-datepicker.css";
const EditUser = (props) => {
  const [date, setDate] = useState(props.user.birthdate);
  const [startDate, setStartDate] = useState(new Date(props.user.birthdate));
  const [image, setImage] = useState("");
  const [profileHtmlelEment, setProfileHtmlelEment] = useState(
    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [alertDailogBoxTitle, setAlertDailogBoxTitle] = useState("");
  const [alertOpenDailog, setAlertOpenDailog] = useState(false);
  const [alertDailogBoxDes, setAlertDailogBoxDes] = useState("");
  const [user, setUser] = useState({
    name: props.user.name,
    email: props.user.email,
    phone: props.user.phone,
    password: props.user.password,
    gender: props.user.gender,
    status: props.user.status,
    imgaeurl: props.user.imageUrl,
    collectionName: props.user.collectionName,
  });

  const handleSubmit = () => {
    setIsLoading(true);

    handleImageForUpload();
  };
  const handleImageForUpload = () => {
    const image = image;

    if (image !== "") {
      onUploadImage(image);
    } else {
      onUpdate();
    }
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      setUser({
        ...user,
        imgaeurl: url,
      });
      onUpdate();
    }
  };
  const onUpdate = async () => {
    const sendData = {
      ...user,
      searchbyname: user.name.toLowerCase(),
    };
    const res = await Service.updateData(
      sendData,
      props.user.collectionName,
      props.user.userId
    );
    if (res === "success") {
      setIsLoading(false);
      setOpenAlertDailog(true);
      setAlertDailogBoxTitle("Update");
      setAlertDailogBoxDes("successfully updated");

      let user = user;
      user = {
        ...user,
        collectionName: props.user.collectionName,
        userId: props.user.userId,
      };

      props.setOnuser(user);
    } else {
      setIsLoading(false);
      handleErrorDailog();
    }
  };
  const handleErrorDailog = () => {
    setOpenErrorDailog(true);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setUser({
        ...user,
        birthdate: `${
          birthDate.getMonth() + 1
        }/${birthDate.getDate()}/${birthDate.getFullYear()}`,
      });
      setDate(date);
      setStartDate(date);
    } else {
      setUser({
        ...user,
        birthdate: date,
      });
      setDate(date);
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setUser({
          ...user,
          imgaeurl: e.target.result,
        });
        reader.readAsDataURL(event.target.files[0]);
      };
    }
  };
  const onImageRemove = () => {
    setUser({
      ...user,
      imgaeurl: "",
    });
    setImage("");
  };

  const onEdit = (e) => {
    let user = user;
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const handleSetOpenDailog = () => {
    setAlertOpenDailog(false);
    setOpenAlertDailog(false);
  };

  useEffect(() => {
    if (user.imgaeurl === "") {
      setProfileHtmlelEment(
        <div className="edituserpage">
          <i className="fa fa-user fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      setProfileHtmlelEment(
        <div className="edituserpage">
          <img className="netimage" srcSet={user.imgaeurl} alt="" />
        </div>
      );
    }
  }, [user.imgaeurl]);

  return isLoading ? (
    <div className="edituserpage">
      <i
        className="fas fa-spinner fa-pulse fa-4x"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      ></i>
    </div>
  ) : (
    <div className="edituserpage">
      <AlertDialogBox
        openDailog={openAlertDailog}
        setOpenDailog={setOpenAlertDailog}
        onSetOpenDailog={handleSetOpenDailog}
        title="Update"
        des="successfully updated"
      ></AlertDialogBox>
      <ErorrDialogBox
        openDailog={openErrorDailog}
        onSetOpenDailog={closeErrorDailog}
        title="Error"
        des="Someting went wrong. Try again"
      ></ErorrDialogBox>
      <EditUserForm
        handleSubmit={handleSubmit}
        onEdit={onEdit}
        date={date}
        handleChange={handleChange}
        user={user}
        profileHtmlelEment={profileHtmlelEment}
        onImageRemove={onImageRemove}
        onImageChange={onImageChange}
        handleImageForUpload={handleImageForUpload}
      ></EditUserForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnuser: (p) => {
      dispatch(setuser(p));
    },
  };
};
export default connect(mapStateToProps, mapDisptachToProps)(EditUser);
