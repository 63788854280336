import React, { useState } from "react";
import firebase from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";

import Service from "../../Service/firebase";
import NewSubCategoryForm from "./newsubcategoryform";

import "./addsubcategory.css";
const AddSubCategory = (props) => {
  const [date, setDate] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [SalonTypeList, setSalonTypeList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [htmlElement, setHtmlElement] = useState(
    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  );
  // : "",
  // startDate: new Date(),
  // imageFile: "",

  const [formData, setFormData] = useState({
    name: "",
    categoryId: "",
  });

  const onFetchSalonType = async () => {
    setIsLoadMoreData(true);

    const fetchedSalonDataList = await Service.getData("category");

    if (fetchedSalonDataList.length !== 0) {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  const onAddPerson = async (imageUrl) => {
    let sendData = {
      ...formData,
      searchbyname: formData.name.toLowerCase(),
      // imgaeurl: imageUrl,
      // timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    if (props.collectionName === "subCategory") {
      sendData = {
        ...sendData,
      };
    }

    const res = await Service.addDataSubCategory(
      sendData,
      props.collectionName,
      props.id
    );

    if (res === "success") {
      setIsLoading(false);

      props.handleSuccessDailog();
    } else {
      setIsLoading(false);
      props.handleErrorDailog();
    }
  };
  const handleImageForUpload = () => {
    const image = imageFile;
    const url = "";

    if (imageFile !== "") {
      onUploadImage(image);
    } else {
      onAddPerson(url);
    }
  };
  const handleSubmit = () => {
    setIsLoading(true);
    props.setCloseBtnAppear();
    handleImageForUpload();
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      onAddPerson(url);
    }
  };
  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setDate(date);
      setFormData({
        ...formData,
      });
      setStartDate(date);
    } else {
      setDate(date);
      setFormData({
        ...formData,
      });
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageAvatar(e.target.result);
        setHtmlElement(
          <div className="addpersonpage">
            <img
              className="netimage"
              srcSet={e.target.result}
              alt="profileImage"
            />
          </div>
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onImageRemove = () => {
    setImageFile("");
    setImageAvatar("");
    setHtmlElement(
      <div className="addpersonpage">
        <i className="fa fa-user fa-8x" aria-hidden="true"></i>
      </div>
    );
  };
  const onEdit = (e) => {
    const formData = formData;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // if (imageAvatar === "") {
  //   htmlElement = (
  //     <div className="addpersonpage">
  //       <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  //     </div>
  //   );
  // } else {
  //   htmlElement = (
  //     <div className="addpersonpage">
  //       <img className="netimage" srcSet={imageAvatar} alt="" />
  //     </div>
  //   );
  // }
  return isLoading ? (
    <div className="addpersonpage">
      <i className="fas fa-spinner fa-pulse fa-2x"></i>
    </div>
  ) : (
    <div className="addpersonpage">
      <div className="container main_section">
        <div className="row">
          <div className="col-sm">
            <NewSubCategoryForm
              handleSubmit={handleSubmit}
              onEdit={onEdit}
              // startDate={startDate}
              date={date}
              htmlelement={htmlElement}
              handleChange={handleChange}
              onImageRemove={onImageRemove}
              onImageChange={onImageChange}
            ></NewSubCategoryForm>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddSubCategory;
