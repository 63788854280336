import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "../../firebase";
import Service from "../../Service/firebase";
import NewVendorForm from "./newvendorform";

import "./addvendor.css";
const AddVendor = (props) => {
  const [imageAvatar, setImageAvatar] = useState("");
  const [imagefile, setImagefile] = useState("");
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoredata, setIsLoadMoredata] = useState(false);
  const [SalonTypeList, setSalonTypeList] = useState([]);
  const [htmlelement, setHtmlelement] = useState(
    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  );
  const [formData, setformData] = useState({ name: "" });

  const handleSubmit = () => {
    setIsLoading(true);
    props.setCloseBtnAppear();
    handleImageForUpload();
  };
  const handleImageForUpload = () => {
    const image = imagefile;
    const url = "";
    if (imagefile !== "") {
      onUploadImage(image);
    } else {
      onAddPerson(url);
    }
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      onAddPerson(url);
    }
  };
  const onFetchSalontype = async () => {
    setIsLoading(true);
    setIsLoadMoredata(true);

    const fetchedSalonDataList = await Service.getData("ategory");

    if (fetchedSalonDataList.length !== 0) {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoredata(false);
      setIsLoading(false);
    } else {
      setSalonTypeList(fetchedSalonDataList);
      setIsLoadMoredata(false);
      setIsLoading(false);
    }
  };
  const onAddPerson = async (imageUrl) => {
    let sendData = {
      ...formData,
      searchbyname: formData.name.toLowerCase(),
      imgaeurl: imageUrl,
      timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    if (props.collectionName === "Vendor") {
      sendData = {
        ...sendData,
      };
    }

    const res = await Service.addData(sendData, props.collectionName, props.id);

    if (res === "success") {
      setIsLoading(false);
      props.handleSuccessDailog();
    } else {
      setIsLoading(false);
      props.handleErrorDailog();
    }
  };

  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setDate(date);
      setformData({
        ...formData,
      });
      setStartDate(date);
    } else {
      setDate(date);
      setformData({
        ...formData,
      });
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImagefile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageAvatar(e.target.result);
        setHtmlelement(
          <div className="addpersonpage">
            <img
              className="netimage"
              srcSet={e.target.result}
              alt="profileImage"
            />
          </div>
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onImageRemove = () => {
    setImagefile("");
    setImageAvatar("");
    setHtmlelement(
      <div className="addpersonpage">
        <i className="fa fa-user fa-8x" aria-hidden="true"></i>
      </div>
    );
  };
  const onEdit = (e) => {
    const formData = formData;
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // if (imageAvatar === "") {
  //   htmlelement = (
  //     <div className="addpersonpage">
  //       <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  //     </div>
  //   );
  // } else {
  //   htmlelement = (
  //     <div className="addpersonpage">
  //       <img className="netimage" srcSet={imageAvatar} alt="" />
  //     </div>
  //   );
  // }
  return isLoading ? (
    <div className="addpersonpage">
      <i className="fas fa-spinner fa-pulse fa-2x"></i>
    </div>
  ) : (
    <div className="addpersonpage">
      <div className="container main_section">
        <div className="row">
          <div className="col-sm">
            <NewVendorForm
              handleSubmit={handleSubmit}
              onEdit={onEdit}
              // startDate={startDate}
              date={date}
              htmlelement={htmlelement}
              handleChange={handleChange}
              onImageRemove={onImageRemove}
              onImageChange={onImageChange}
            ></NewVendorForm>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddVendor;
