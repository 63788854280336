import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setsubcategory } from "../../actions/setsubcategoryaction";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import Service from "../../Service/firebase";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import EditSubCategoryForm from "../SubCategory/editsubcategoryform";

import "react-datepicker/dist/react-datepicker.css";
const EditSubCategory = (props) => {
  const [date, setDate] = useState(props.subcategory.birthdate);
  const [startDate, setStartDate] = useState(
    new Date(props.subcategory.birthdate)
  );
  const [image, setImage] = useState("");
  const [profileHtmlElement, setProfileHtmlElement] = useState(
    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [alertDialogBoxTitle, setAlertDialogBoxTitle] = useState("");
  const [alertDialogBoxDes, setAlertDialogBoxDes] = useState("");

  const [subcategory, setSubcategory] = useState({
    name: props.subcategory.name,
    imgaeurl: props.subcategory.imgaeurl,
    categoryid: props.subcategory.categoryid,
    collectionName: props.subcategory.collectionName,
  });

  const handleSubmit = () => {
    setIsLoading(true);

    handleImageForUpload();
  };
  const handleImageForUpload = () => {
    if (image !== "") {
      onUploadImage(image);
    } else {
      onUpdate();
    }
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      setSubcategory({
        ...subcategory,
        imgaeurl: url,
      });
      onUpdate();
    }
  };
  const onUpdate = async () => {
    const sendData = {
      ...subcategory,
      searchbyname: subcategory.name.toLowerCase(),
    };
    const res = await Service.updateData(
      sendData,
      props.subcategory.collectionName,
      props.subcategory.subcategoryId
    );
    if (res === "success") {
      setIsLoading(false);
      setOpenAlertDialog(true);
      setAlertDialogBoxTitle("Update");
      setAlertDialogBoxDes("successfully updated");

      let subcategory = subcategory;
      subcategory = {
        ...subcategory,
        collectionName: props.subcategory.collectionName,
        subcategoryId: props.subcategory.subcategoryId,
      };

      props.setOnsubcategory(subcategory);
    } else {
      setIsLoading(false);
      handleErrorDialog();
    }
  };
  const handleErrorDialog = () => {
    setOpenErrorDialog(true);
  };
  const closeErrorDialog = () => {
    setOpenErrorDialog(false);
  };
  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setSubcategory({
        ...subcategory,
        birthdate: `${
          birthDate.getMonth() + 1
        }/${birthDate.getDate()}/${birthDate.getFullYear()}`,
      });
      setDate(date);
      setStartDate(date);
    } else {
      setSubcategory({
        ...subcategory,
        birthdate: date,
      });
      setDate(date);
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setSubcategory({
          ...subcategory,
          imgaeurl: e.target.result,
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onImageRemove = () => {
    setSubcategory({
      ...subcategory,
      imgaeurl: "",
    });
    setImage("");
  };

  const onEdit = (e) => {
    let subcategory = subcategory;
    setSubcategory({
      ...subcategory,
      [e.target.name]: e.target.value,
    });
  };
  const handleSetOpenDailog = () => {
    setOpenAlertDialog(false);
  };

  useEffect(() => {
    if (subcategory.imgaeurl === "") {
      setProfileHtmlElement(
        <div className="editcategorypage">
          <i className="fa fa-user fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      setProfileHtmlElement(
        <div className="editcategorypage">
          <img className="netimage" srcSet={subcategory.imgaeurl} alt="" />
        </div>
      );
    }
  }, [subcategory.imgaeurl]);

  return isLoading ? (
    <div className="editcategorypage">
      <i
        className="fas fa-spinner fa-pulse fa-4x"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      ></i>
    </div>
  ) : (
    <div className="editcategorypage">
      <AlertDialogBox
        openDailog={openAlertDialog}
        setOpenDailog={setOpenAlertDialog}
        onSetOpenDailog={handleSetOpenDailog}
        title="Update"
        des="successfully updated"
      ></AlertDialogBox>
      <ErorrDialogBox
        openDailog={openErrorDialog}
        onSetOpenDailog={closeErrorDialog}
        title="Error"
        des="Someting went wrong. Try again"
      ></ErorrDialogBox>
      <EditSubCategoryForm
        handleSubmit={handleSubmit}
        onEdit={onEdit}
        date={date}
        handleChange={handleChange}
        subcategory={subcategory}
        profileHtmlelEment={profileHtmlElement}
        onImageRemove={onImageRemove}
        onImageChange={onImageChange}
        handleImageForUpload={handleImageForUpload}
      ></EditSubCategoryForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    subcategory: state.subcategory,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnsubcategory: (p) => {
      dispatch(setsubcategory(p));
    },
  };
};
export default connect(mapStateToProps, mapDisptachToProps)(EditSubCategory);
