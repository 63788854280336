import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setvendor } from "../../actions/setvendoraction";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import Service from "../../Service/firebase";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import EditVendorForm from "../Vendors/editvendorform";

import "react-datepicker/dist/react-datepicker.css";
const EditVendor = (props) => {
  const [date, setDate] = useState(props.vendor.birthdate);
  const [startDate, setStartDate] = useState(new Date(props.vendor.birthdate));
  const [image, setImage] = useState("");
  const [profileHtmlelEment, setProfileHtmlelEment] = useState(
    <i className="fa fa-vendor fa-8x" aria-hidden="true"></i>
  );

  const [salonimage, setSalonimage] = useState("");
  const [profileHtmlelEment2, setProfileHtmlelEment2] = useState(
    <i className="fa fa-vendor fa-8x" aria-hidden="true"></i>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [alertDailogBoxTitle, setAlertDailogBoxTitle] = useState("");
  const [alertDailogBoxDes, setAlertDailogBoxDes] = useState("");
  const [vendor, setVendor] = useState({
    name: props.vendor.name,
    email: props.vendor.email,
    phoneNumber: props.vendor.phoneNumber,
    // status: props.vendor.status,
    salonName: props.vendor.salonName,
    salonAddress: props.vendor.salonAddress,
    shopImage: props.vendor.shopImage,
    vendorImage: props.vendor.vendorImage,

    collectionName: props.vendor.collectionName,
  });
  const handleSubmit = () => {
    setIsLoading(true);

    handleImageForUpload();
  };
  const handleImageForUpload = () => {
    const image = image;

    if (image !== "") {
      onUploadImage(image);
    } else {
      onUpdate();
    }
  };
  const onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      setVendor({
        ...vendor,
        vendorImage: url,
      });
      onUpdate();
    }
  };
  //----------------------------------//
  const handleSalonImageForUpload = () => {
    const image = image;

    if (image !== "") {
      onUploadImage(image);
    } else {
      onUpdate();
    }
  };
  const onUploadSalonImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      setVendor({
        ...vendor,
        shopImage: url,
      });

      onUpdate();
    }
  };
  const onUpdate = async () => {
    const sendData = {
      ...vendor,
      searchbyname: vendor.name.toLowerCase(),
    };
    const res = await Service.updateData(
      sendData,
      props.vendor.collectionName,
      props.vendor.uid
    );
    if (res === "success") {
      setIsLoading(false);
      setOpenAlertDailog(true);
      setAlertDailogBoxTitle("Update");
      setAlertDailogBoxDes("successfully updated");

      let vendor = vendor;
      vendor = {
        ...vendor,
        collectionName: props.vendor.collectionName,
        vendorId: props.vendor.uid,
      };

      props.setOnvendor(vendor);
    } else {
      setIsLoading(false);
      handleErrorDailog();
    }
  };
  const handleErrorDailog = () => {
    setOpenErrorDailog(true);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);
      setVendor({
        ...vendor,
        birthdate: `${
          birthDate.getMonth() + 1
        }/${birthDate.getDate()}/${birthDate.getFullYear()}`,
      });
      setDate(date);
      setStartDate(date);
    } else {
      setVendor({
        ...vendor,
        birthdate: date,
      });
      setDate(date);
      setStartDate(date);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setVendor({
          ...vendor,
          vendorImage: e.target.result,
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onImageRemove = () => {
    setVendor({
      ...vendor,
      vendorImage: "",
    });
    setImage("");
  };
  //--------------------------//
  const onSalonImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setVendor({
        ...vendor,
        shopImage: event.target.result,
      });
      let reader = new FileReader();
      reader.onload = (e) => {
        setVendor({
          ...vendor,
          shopImage: e.target.result,
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const onSalonImageRemove = () => {
    setVendor({
      ...vendor,
      shopImage: "",
    });
    setImage("");
  };
  const onEdit = (e) => {
    let vendor = vendor;
    setVendor({
      ...vendor,
      [e.target.name]: e.target.value,
    });
  };
  const handleSetOpenDailog = () => {
    setOpenAlertDailog(false);
  };

  useEffect(() => {
    if (vendor.imgaeurl === "") {
      setProfileHtmlelEment(
        <div className="editvendorpage">
          <i className="fa fa-vendor fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      setProfileHtmlelEment(
        <div className="editvendorpage">
          <img className="netimage" srcSet={vendor.vendorImage} alt="" />
        </div>
      );
    }
    if (vendor.shopImage === "") {
      setProfileHtmlelEment2(
        <div className="editvendorpage">
          <i className="fa fa-vendor fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      setProfileHtmlelEment2(
        <div className="editvendorpage">
          <img className="netimage" srcSet={vendor.shopImage} alt="" />
        </div>
      );
    }
  }, [vendor.imgaeurl, vendor.shopImage]);

  return isLoading ? (
    <div className="editvendorpage">
      <i
        className="fas fa-spinner fa-pulse fa-4x"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      ></i>
    </div>
  ) : (
    <div className="editvendorpage">
      <AlertDialogBox
        openDailog={openAlertDailog}
        setOpenDailog={setOpenAlertDailog}
        onSetOpenDailog={handleSetOpenDailog}
        title="Update"
        des="successfully updated"
      ></AlertDialogBox>
      <ErorrDialogBox
        openDailog={openErrorDailog}
        onSetOpenDailog={closeErrorDailog}
        title="Error"
        des="Someting went wrong. Try again"
      ></ErorrDialogBox>
      <EditVendorForm
        handleSubmit={handleSubmit}
        onEdit={onEdit}
        date={date}
        handleChange={handleChange}
        vendor={vendor}
        profileHtmlelEment={profileHtmlelEment}
        profileHtmlelEment2={profileHtmlelEment2}
        onImageRemove={onImageRemove}
        onImageChange={onImageChange}
        handleImageForUpload={handleImageForUpload}
        onSalonImageRemove={onSalonImageRemove}
        onSalonImageChange={onSalonImageChange}
        handleSalonImageForUpload={handleSalonImageForUpload}
      ></EditVendorForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    vendor: state.vendor,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnvendor: (p) => {
      dispatch(setvendor(p));
    },
  };
};
export default connect(mapStateToProps, mapDisptachToProps)(EditVendor);
