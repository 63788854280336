import React, { useState, useEffect } from "react";

import Service from "../../Service/firebase";

import "./dashboard.css";
import { subDays, addYears, subYears } from "date-fns";

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [getAllDocCount, setGetAllDocCount] = useState({});
  const [totalBookingCount, setTotalBookingCount] = useState(0);
  const [todaysBookingCount, setTodaysBookingCount] = useState(0);
  const [lastWeekBookingCount, setLastWeekBookingCount] = useState(0);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getAllDocCountfunc();
      getAllBookingCount();
      getTodayBookingCount();
      getLastWeekBookingCount();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const getAllDocCountfunc = async () => {
    setIsLoading(true);
    const allDocCount = await Service.getDocCount();
    setGetAllDocCount(allDocCount);
    setIsLoading(false);
  };

  const getAllBookingCount = async () => {
    setIsLoading(true);
    const today = new Date();

    const startDate = subYears(today, 20);
    const endDate = addYears(today, 20);

    const bookingCount = await Service.getBookingsCountWithinDateRangeAndStatus(
      startDate,
      endDate,
      "Completed"
    );
    setTotalBookingCount(bookingCount);
    setIsLoading(false);
  };
  const getTodayBookingCount = async () => {
    setIsLoading(true);
    const today = new Date();

    const bookingCount = await Service.getBookingsCountWithinDateRangeAndStatus(
      today,
      today,
      "Completed"
    );
    setTodaysBookingCount(bookingCount);
    setIsLoading(false);
  };

  const getLastWeekBookingCount = async () => {
    const today = new Date();
    const lastWeekDate = subDays(today, 7);

    const bookingCount = await Service.getBookingsCountWithinDateRangeAndStatus(
      lastWeekDate,
      today,
      "Completed"
    );
    setLastWeekBookingCount(bookingCount);
  };
  return isLoading ? (
    <div className="dashboardpage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="dashboardpage">
      <div className="topheader">
        <ul>
          <li>
            <i
              className="fa fa-arrow-circle-o-right fa-2x"
              aria-hidden="true"
            ></i>
          </li>
          <li>
            <span>Dashboard</span>
          </li>
        </ul>
      </div>

      <div className="first_section">
        <div className="row">
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#BA79CB",
                  }}
                >
                  {getAllDocCount.salonType}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#BA79CB",
                  }}
                >
                  Salon Type
                </span>
              </div>
              <i className="fa fa-user fa-4x" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#FFA812",
                  }}
                >
                  {getAllDocCount.category}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#FFA812",
                  }}
                >
                  Category
                </span>
              </div>
              <i className="fa fa-user-md fa-4x" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#00A65A",
                  }}
                >
                  {getAllDocCount.subCategory}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#00A65A",
                  }}
                >
                  Subcategory
                </span>
              </div>
              <i className="fa fa-female fa-4x" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#F56954",
                  }}
                >
                  {getAllDocCount.users}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#F56954",
                  }}
                >
                  Users
                </span>
              </div>
              <i className="fa fa-medkit fa-4x" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#00B29E",
                  }}
                >
                  {getAllDocCount.vendors}
                </h2>
                <span
                  style={{
                    fontWeight: "700",
                    color: "#00B29E",
                  }}
                >
                  Vendors
                </span>
              </div>
              <i className="fa fa-flask fa-4x" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#EC3B83",
                  }}
                >
                  {totalBookingCount}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#EC3B83",
                  }}
                >
                  Total Bookings
                </span>
              </div>
              <i className="fa fa-money fa-4x" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#00C0EF",
                  }}
                >
                  {todaysBookingCount}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#00C0EF",
                  }}
                >
                  Today Bookings
                </span>
              </div>
              <i className="fa fa-briefcase fa-4x" aria-hidden="true"></i>
            </div>
          </div>

          <div className="col-sm">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#BA79CB",
                  }}
                >
                  {lastWeekBookingCount}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#BA79CB",
                  }}
                >
                  Last Week Bookings
                </span>
              </div>
              <i className="fa fa-credit-card-alt fa-4x" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="box">
              <div className="box_containt">
                <h2
                  className="heading"
                  style={{
                    color: "#00A65A",
                  }}
                >
                  {getAllDocCount.operationreport}
                </h2>
                {/* <hr /> */}
                <span
                  style={{
                    fontWeight: "700",
                    color: "#00A65A",
                  }}
                >
                  Payment Total
                </span>
              </div>
              <i className="fa fa-hospital-o fa-4x" aria-hidden="true"></i>
            </div>
          </div>

          <div className="col-sm-3"> </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
