import React, { useState, useEffect } from "react";

import Service from "../../Service/firebase";

import "./newcategoryform.css";
const NewCategoryForm = (props) => {
  const [salons, setSalons] = useState([]);
  const [salonLists, setSalonLists] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  const [state, setState] = useState({});
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onFetchSalonType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onFetchSalonType = async () => {
    setIsLoading(true);

    const fetchedSalonDataList = await Service.getAllData("salonType");
    console.log(fetchedSalonDataList);
    if (fetchedSalonDataList.length == 0) {
      // setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
      // console.log("hello yd 4");
      //console.log(salonlists);
    }
  };
  const handleClickButton = (e) => {
    console.log(e);
    console.log(e.target.value);
    setState({
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    //   console.log(event.target.getAttribute())
    // setState({
    //   selectedSalon
    //   },() => {
    //     console.log(`Option selected:`, selectedSalon);
    //   });
  };
  // const { salonlists } = salons ;

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="container">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Name</label>
            <input
              name="name"
              type="text"
              className="form-control"
              id="name"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Salon Type</label>
            <select
              name="salonTypeId"
              //id="salontypeid"
              className="form-control"
              //onChange={handleChange}
              onChange={props.onEdit}
            >
              <option value="">Select Salon type</option>

              {salons?.map((option) => (
                <option
                  value={option.salonTypeId}
                  value-id={option.name}
                  key={option.salonTypeId}
                >
                  {option.name}
                </option>
                // <option value={option.name} value-id={option.name} >{option.name}</option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>Salon Type</label>
              <input
              value={salons.map(name)}
              readOnly/>
            </div>
          </div> */}
      </div>

      <div className="container">
        <div className="row second_section">
          <div className="clo-sm-6">
            <div className="profileimage">{props.htmlelement}</div>
          </div>

          <div className="col-sm-6 btn_section">
            <label htmlFor="files" className="selectimage">
              Upload Image
            </label>
            <input
              name="avatarimage"
              id="files"
              type="file"
              onChange={props.onImageChange}
            />
            <button
              type="button"
              className="removebutton"
              onClick={props.onImageRemove}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <button className="btn btn-success savebtn" type="submit">
        Save
      </button>
    </form>
  );
};

export default NewCategoryForm;
