import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { setbanner } from "../../actions/setbanneraction";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import Service from "../../Service/firebase";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";

import EditBannerForm from "../Banner/editbannerform";

class EditBanner extends Component {
  constructor(props) {
    super(props);
    console.log("hey yd hey yd banner" +this.props.banner.name);
    this.state = {
      date: this.props.banner.birthdate,
      startDate: new Date(this.props.banner.birthdate),
      image: "",
      profileHtmlelEment: (
        <i className="fa fa-user fa-8x" aria-hidden="true"></i>
      ),
      isLoading: false,
      openAlertDailog: false,
      openErrorDailog: false,

      banner: {
        
       
        name: this.props.banner.name,
        imgaeurl: this.props.banner.imgaeurl,
       
        collectionName: this.props.banner.collectionName,
      },
    };
  }

  handleSubmit = () => {
    this.setState({
      isLoading: true,
    });

    this.handleImageForUpload();
  };
  handleImageForUpload = () => {
    const image = this.state.image;

    if (this.state.image !== "") {
      this.onUploadImage(image);
    } else {
      this.onUpdate();
    }
  };
  onUploadImage = async (image) => {
    const url = await Service.uploadImage(image);
    if (url !== "") {
      this.setState({
        banner: {
          ...this.state.banner,
          imgaeurl: url,
        },
      });

      this.onUpdate();
    }
  };
  async onUpdate() {
    const sendData = {
      ...this.state.banner,
      searchbyname: (
        this.state.banner.name
      ).toLowerCase(),
    };
    const res = await Service.updateData(
      sendData,
      this.props.banner.collectionName,
      this.props.banner.bannerId
    );
    if (res === "success") {
      this.setState({
        isLoading: false,
        openAlertDailog: true,
        alertDailogBoxTitle: "Update",
        alertDailogBoxDes: "successfully updated",
      });

      let banner = this.state.banner;
      banner = {
        ...this.state.banner,
        collectionName: this.props.banner.collectionName,
        bannerId: this.props.banner.bannerId,
      };

      this.props.setOnbanner(banner);
    } else {
      this.setState({
        isLoading: false,
      });
      this.handleErrorDailog();
    }
  }
  handleErrorDailog = () => {
    this.setState({
      openErrorDailog: true,
    });
  };
  closeErrorDailog = () => {
    this.setState({
      openErrorDailog: false,
    });
  };
  handleChange = (date) => {
    if (date !== null) {
      const birthDate = new Date(date);

      this.setState({
        banner: {
          ...this.state.banner,
          birthdate: `${
            birthDate.getMonth() + 1
          }/${birthDate.getDate()}/${birthDate.getFullYear()}`,
        },
        date: date,
        startDate: date,
      });
    } else {
      this.setState({
        banner: {
          ...this.state.banner,
          birthdate: date,
        },
        date: date,
        startDate: date,
      });
    }
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        image: event.target.files[0],
      });
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          banner: {
            ...this.state.banner,
            imgaeurl: e.target.result,
          },
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  onImageRemove = () => {
    this.setState({
      banner: {
        ...this.state.banner,
        imgaeurl: "",
      },
      image: "",
    });
  };

  onEdit = (e) => {
    let banner = this.state.banner;

    this.setState({
      banner: {
        ...this.state.banner,
        [e.target.name]: e.target.value,
      },
    });
    console.log(banner);
  };
  handleSetOpenDailog = () => {
    this.setState({
      setAlertOpenDailog: false,
      openAlertDailog: false,
    });
  };

  render() {
    if (this.state.banner.imgaeurl === "") {
      this.state.profileHtmlelEment = (
        <div className="editbannerpage">
          <i className="fa fa-user fa-8x" aria-hidden="true"></i>
        </div>
      );
    } else {
      this.state.profileHtmlelEment = (
        <div className="editbannerpage">
          <img
            className="netimage"
            srcSet={this.state.banner.imgaeurl}
            alt=""
          />
        </div>
      );
    }

    return this.state.isLoading ? (
      <div className="editbannerpage">
        <i
          className="fas fa-spinner fa-pulse fa-4x"
          style={{ position: "fixed", top: "50%", left: "50%" }}
        ></i>
      </div>
    ) : (
      <div className="editbannerpage">
        <AlertDialogBox
          openDailog={this.state.openAlertDailog}
          setOpenDailog={this.state.setOpenAlertDailog}
          onSetOpenDailog={this.handleSetOpenDailog}
          title="Update"
          des="successfully updated"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={this.state.openErrorDailog}
          onSetOpenDailog={this.closeErrorDailog}
          title="Error"
          des="Someting went wrong. Try again"
        ></ErorrDialogBox>
        <EditBannerForm
          handleSubmit={this.handleSubmit}
          onEdit={this.onEdit}
          date={this.state.date}
          handleChange={this.handleChange}
          banner={this.state.banner}
          profileHtmlelEment={this.state.profileHtmlelEment}
          onImageRemove={this.onImageRemove}
          onImageChange={this.onImageChange}
          handleImageForUpload={this.handleImageForUpload}
        ></EditBannerForm>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banner: state.banner,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnbanner: (p) => {
      dispatch(setbanner(p));
    },
  };
};
export default connect(mapStateToProps, mapDisptachToProps)(EditBanner);
