import React, { Component } from "react";

import "./editvendorform.css";
const EditVendorForm = (props) => {
  return (
    <div className="ditd_salon_type_formpage">
      <div className="container main_section">
        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>{props.vendor.collectionName}</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-8 first_section">
            <form onSubmit={props.handleSubmit}>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.vendor.name}
                    onChange={props.onEdit}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Email</label>
                  <input
                    name="email"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.vendor.email}
                    onChange={props.onEdit}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Phone</label>
                  <input
                    name="phoneNumber"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.vendor.phoneNumber}
                    onChange={props.onEdit}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Salon name</label>
                  <input
                    name="salonName"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.vendor.salonName}
                    onChange={props.onEdit}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Salon address</label>
                  <input
                    name="salonAddress"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.vendor.salonAddress}
                    onChange={props.onEdit}
                  />
                </div>
              </div>
              {/* <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationDefault01">Status</label>
                    <select 
              name="status"
              id="validationDefault01"
              className="form-control"
              value={props.vendor.status}
              onChange={props.onEdit}
              > 
          <option value='true' >Active</option> 
          <option value='false' >In-active</option> 
          </select>   
                  </div>        
                </div> */}

              <div className="row">
                <div className="col-sm-6 second_section">
                  <div className="profileimage">{props.profileHtmlelEment}</div>
                  <div className="btn_section">
                    <label htmlFor="files" className="selectimage">
                      User Upload Image
                    </label>
                    <input
                      name="vendorImage"
                      id="files"
                      type="file"
                      onChange={props.onImageChange}
                    />
                    <button
                      type="button"
                      className="removebutton"
                      onClick={props.onImageRemove}
                    >
                      {" "}
                      Remove
                    </button>
                  </div>

                  <input
                    id="files"
                    type="file"
                    onChange={props.onImageChange}
                  />
                </div>
                <div className="col-sm-6 second_section">
                  <div className="profileimage">
                    {props.profileHtmlelEment2}
                  </div>
                  <div className="btn_section">
                    <label htmlFor="files2" className="selectimage">
                      Salon Upload Image
                    </label>
                    <input
                      name="shopImage"
                      id="files2"
                      type="file"
                      onChange={props.onSalonImageChange}
                    />
                    <button
                      type="button"
                      className="removebutton"
                      onClick={props.onSalonImageRemove}
                    >
                      {" "}
                      Remove
                    </button>
                  </div>

                  <input
                    id="files2"
                    type="file"
                    onChange={props.onSalonImageChange}
                  />
                </div>
              </div>
              <button className="btn btn-success update_btn" type="submit">
                Update
              </button>
            </form>
          </div>
          <div className="col-sm-4 second_section">
            {/* <div className="profileimage">
                {props.profileHtmlelEment}
              </div>
              <div className="btn_section">
                <label htmlFor="files" className="selectimage">
                  Upload Image
                </label>
                <input
                  name="avatarimage"
                  id="files"
                  type="file"
                  onChange={props.onImageChange}
                />
                <button
                  type="button"
                  className="removebutton"
                  onClick={props.onImageRemove}
                >
                  {" "}
                  Remove
                </button>
              </div>

              <input id="files" type="file" onChange={onImageChange} />
          */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVendorForm;
