import React, { useState } from "react";
import {
  BrowserRouter as Routers,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import SalontypeList from "./SalonType/salontypelist";
import CategoryList from "./Category/categorylist";
import SubCategoryList from "./SubCategory/subcategorylist";
import EditPersonDetails from "./Doctors/editpersondetails";
import EditSalonType from "./SalonType/editsalontype";
import Dashboard from "./Dashboard/dashboard";
import { setLogInDetails } from "../actions/setpersondetailsaction";
import Editcategory from "./Category/editcategory";
import Editsubcategory from "./SubCategory/editsubcategory";
import AddCategory from "./Category/addcategory";
import UserList from "./Users/userlist";
import EditUser from "./Users/edituser";
import VendorList from "./Vendors/vendorlist";
import RequestVendorList from "./Vendors/requestvendorlist";
import EditVendor from "./Vendors/editvendor";
import BannerList from "./Banner/bannerlist";
import EditBanner from "./Banner/editbanner";
import DailyBooking from "./Booking/todaybookinglist";
import firebase from "../firebase";

import "./sliderNavBar.css";

const SideNavBar = (props) => {
  const [addHamburgerClass, setAddHamburgerClass] = useState(false);
  const [addTitleClass, setAddTitleClass] = useState(false);
  const [togglePymentTitle, setTogglePymentTitle] = useState(true);
  const [selectedCat, setSelectedCat] = useState("");

  const toggleHamburger = () => {
    setAddHamburgerClass(!addHamburgerClass);
  };
  const toggleTitle = () => {
    setAddTitleClass(!addTitleClass);
  };
  const togglePymentTitleFunc = () => {
    setTogglePymentTitle(!togglePymentTitle);
  };
  const logout = () => {
    const loginDetails = { isLoggedIn: false };
    firebase.auth().signOut();
    props.setOnLogInDetails(loginDetails);
  };
  const setTitleActive = (selectedCat) => {
    setSelectedCat(selectedCat);
  };

  let boxClass = ["wrapper"];

  if (addHamburgerClass) {
    boxClass.push("collap");
  }
  let titleClass = ["subcat"];
  if (addTitleClass) {
    titleClass.push("collap");
  }
  let togglePymentTitleArr = ["subcat"];
  if (togglePymentTitle) {
    togglePymentTitleArr.push("collap");
  }
  return (
    <div className={boxClass.join(" ")}>
      <Routers>
        <div className="top_navbar">
          <div className="hamburger" onClick={toggleHamburger}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="top_menu">
            <div className="logo">Ems Admin Management</div>
            <ul>
              <li onClick={logout}>
                {" "}
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </li>
              <li>
                <i className="fas fa-search"></i>
              </li>
              <li>
                <i className="fas fa-bell"></i>
              </li>
              <li>
                <i className="fas fa-user"></i>
              </li>
            </ul>
          </div>
        </div>

        <div className="sidebar">
          <div className="noSubCat">
            <ul>
              <Link to="/">
                <li
                  className={selectedCat === "Dashboard" ? "active" : ""}
                  onClick={() => setTitleActive("Dashboard")}
                >
                  <span className="icon">
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </span>
                  <span className="title">Dashboard</span>
                </li>
              </Link>
              <Link to="/bannerlist">
                <li
                  className={selectedCat === "Banner" ? "active" : ""}
                  onClick={() => setTitleActive("Banner")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">Banner</span>
                </li>
              </Link>
              <Link to="/salontypelist">
                <li
                  className={selectedCat === "Salontype" ? "active" : ""}
                  onClick={() => setTitleActive("Salontype")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">Salontype</span>
                </li>
              </Link>
              <Link to="/categorylist">
                <li
                  className={selectedCat === "Category" ? "active" : ""}
                  onClick={() => setTitleActive("Category")}
                >
                  <span className="icon">
                    <i className="fa fa-cut" aria-hidden="true"></i>
                  </span>
                  <span className="title">Category</span>
                </li>
              </Link>

              <Link to="/subcategorylist">
                <li
                  className={selectedCat === "SubCategory" ? "active" : ""}
                  onClick={() => setTitleActive("SubCategory")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">SubCategory</span>
                </li>
              </Link>
              <Link to="/userlist">
                <li
                  className={selectedCat === "User" ? "active" : ""}
                  onClick={() => setTitleActive("User")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">User</span>
                </li>
              </Link>
              <Link to="/vendorlist">
                <li
                  className={selectedCat === "Vendor" ? "active" : ""}
                  onClick={() => setTitleActive("Vendor")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">Vendor</span>
                </li>
              </Link>
              <Link to="/todaybookinglist">
                <li
                  className={selectedCat === "Booking" ? "active" : ""}
                  onClick={() => setTitleActive("Booking")}
                >
                  <span className="icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="title">Booking</span>
                </li>
              </Link>
            </ul>
          </div>
          <hr />

          <div className="withsubcat">
            <div className="maincat">
              <ul>
                <li onClick={toggleTitle}>
                  <span className="icon"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          <div className="main_container">
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/salontypelist">
                <SalontypeList />
              </Route>
              <Route path="/categorylist">
                <CategoryList />
              </Route>
              <Route path="/addcategory">
                <AddCategory />
              </Route>
              <Route path="/subcategorylist">
                <SubCategoryList />
              </Route>
              <Route path="/userlist">
                <UserList />
              </Route>
              <Route path="/edituser">
                <EditUser />
              </Route>
              <Route path="/vendorlist">
                <VendorList />
              </Route>
              <Route path="/requestvendorlist">
                <RequestVendorList />
              </Route>
              <Route path="/editvendor">
                <EditVendor />
              </Route>
              <Route path="/bannerlist">
                <BannerList />
              </Route>
              <Route path="/editbanner">
                <EditBanner />
              </Route>

              <Route path="/editsalontype">
                <EditSalonType />
              </Route>
              <Route path="/editcategory">
                <Editcategory />
              </Route>
              <Route path="/editsubcategory">
                <Editsubcategory />
              </Route>
              <Route path="/todaybookinglist">
                <DailyBooking />
              </Route>
              <Route path="/editpersondetails">
                <EditPersonDetails />
              </Route>

              <Redirect to="" />
            </Switch>
          </div>
        }
      </Routers>
    </div>
  );
};
const mapDisptachToProps = (dispatch) => {
  return {
    setOnLogInDetails: (p) => {
      dispatch(setLogInDetails(p));
    },
  };
};
export default connect(null, mapDisptachToProps)(SideNavBar);
