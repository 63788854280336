import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Service from "../../Service/firebase";

import "./editcategoryform.css";
const EditCategoryForm = (props) => {
  const [salons, setSalons] = useState([]);
  const [salonLists, setSalonLists] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onFetchSalonType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onFetchSalonType = async () => {
    setIsLoading(true);

    const fetchedSalonDataList = await Service.getAllData("salonType");
    console.log(fetchedSalonDataList.length);
    if (fetchedSalonDataList.length == 0) {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
      //console.log(salonLists);
    }
  };

  return (
    <div className="editd_person_details_formpage">
      <div className="container main_section">
        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>{props.category.collectionName}</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-8 first_section">
            <form onSubmit={props.handleSubmit}>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.category.name}
                    onChange={props.onEdit}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Salon Type</label>
                  <select
                    name="salonTypeId"
                    id="salonTypeId"
                    className="form-control"
                    value={props.category.salonTypeId}
                    onChange={props.onEdit}
                  >
                    <option value="">Select Salon type</option>

                    {salons.map((option) => (
                      <option
                        key={option.salonTypeId}
                        value={option.salonTypeId}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefault01">Salon Type Name</label>
                  <input
                    name="salonTypeName"
                    type="text"
                    className="form-control"
                    id="validationDefault01"
                    required
                    value={props.category.salonTypeName}
                    onChange={props.onEdit}
                    readOnly
                  />
                </div>
              </div>

              <button className="btn btn-success update_btn" type="submit">
                Update
              </button>
            </form>
          </div>
          <div className="col-sm-4 second_section">
            <div className="profileimage">{props.profileHtmlelEment}</div>
            <div className="btn_section">
              <label htmlFor="files" className="selectimage">
                Upload Image
              </label>
              <input
                name="avatarimage"
                id="files"
                type="file"
                onChange={props.onImageChange}
              />
              <button
                type="button"
                className="removebutton"
                onClick={props.onImageRemove}
              >
                Remove
              </button>
            </div>
            <input id="files" type="file" onChange={props.onImageChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategoryForm;
