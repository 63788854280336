import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./editpersondetailsform.css";
import Reports from "./reports";

class EditPersonDetailsForm extends Component {
  render() {
    return (
      <div className="editd_person_details_formpage">
        <div className="container main_section">
          <div className="topheader">
            <ul>
              <li>
                <i
                  className="fa fa-arrow-circle-o-right fa-2x"
                  aria-hidden="true"
                ></i>
              </li>
              <li>
                <h5>{this.props.category.collectionName}</h5>
              </li>
            </ul>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-8 first_section">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationDefault01"> name</label>
                    <input
                      name="firstname"
                      type="text"
                      className="form-control"
                      id="validationDefault01"
                      required
                      value={this.props.category.name}
                      onChange={this.props.onEdit}
                    />
                  </div>
                 
                </div>

              
               

               
               
               
                

               

                <button className="btn btn-success update_btn" type="submit">
                  Update
                </button>
              </form>
            </div>
            <div className="col-sm-4 second_section">
              <div className="profileimage">
                {this.props.profileHtmlelEment}
              </div>
              <div className="btn_section">
                <label htmlFor="files" className="selectimage">
                  Upload Image
                </label>
                <input
                  name="avatarimage"
                  id="files"
                  type="file"
                  onChange={this.props.onImageChange}
                />
                <button
                  type="button"
                  className="removebutton"
                  onClick={this.props.onImageRemove}
                >
                  {" "}
                  Remove
                </button>
              </div>

              <input id="files" type="file" onChange={this.onImageChange} />
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default EditPersonDetailsForm;
