import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import FormPrompt from "../DailogBoxes/formprompt";
import AlertDialogBox from "../DailogBoxes/alertdailogbox";
import ConfirmDialogBox from "../DailogBoxes/confirmdailogbox";
import ErorrDialogBox from "../DailogBoxes/errordaologbox";
import Service from "../../Service/firebase";
import AddBanner from "./addbanner";
import { setbanner } from "../../actions/setbanneraction.js";
import { searchTextRegex } from "../../constant/regex";

import "./bannerlist.css";

const BannerList = (props) => {
  const [serachText, setSerachText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [isLoadMoredata, setIsLoadMoredata] = useState(false);
  const [isCloseBtnAppear, setIsCloseBtnAppear] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalNumOfBanner, setTotalNumOfBanner] = useState(null);
  const [noMoreDataText, setNoMoreDataText] = useState("");
  const [openFormDailog, setOpenFormDailog] = useState(false);
  const [openAlertDailog, setOpenAlertDailog] = useState(false);
  const [openErrorDailog, setOpenErrorDailog] = useState(false);
  const [openConfirmDailog, setOpenConfirmDailog] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchDataShow, setIsSearchDataShow] = useState(false);
  const [selectedBannerName, setSelectedBannerName] = useState("");
  const [selectedBannerId, setSelectedBannerId] = useState("");

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onSetTotalNumOfBanner();
      onFetchData(limit);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const showMore = () => {
    if (limit <= totalNumOfBanner) {
      const limit = limit + 10;
      setLimit(limit);
      onFetchData(limit);
    } else {
      setNoMoreDataText("No Morebanner");
    }
  };
  const onSetTotalNumOfBanner = async () => {
    setIsLoading(true);
    const res = await Service.getTotalNumOfPerson("banner");
    if (res === "error") {
      console.log("error");
      setIsLoading(false);
    } else {
      setTotalNumOfBanner(res);
      setIsLoading(false);
    }
  };

  const onFetchData = async (limit) => {
    setIsLoadMoredata(true);

    const fetchedDataList = await Service.getData("banner", limit);

    if (fetchedDataList.length !== 0) {
      setBannerList(fetchedDataList);
      setIsLoadMoredata(false);
      setIsLoading(false);
    } else {
      setBannerList(fetchedDataList);
      setIsLoadMoredata(false);
      setIsLoading(false);
    }
  };

  const handleOnDelete = (bannername, id) => {
    setSelectedBannerName(bannername);
    setSelectedBannerId(id);
    setOpenConfirmDailog(true);
  };
  const deleteData = async () => {
    setIsDeleting(true);
    const res = await Service.deleteData("banner", selectedBannerId);
    if (res === "success") {
      setIsDeleting(false);
      setOpenConfirmDailog(false);
      window.location.reload(false);
    } else {
      setIsDeleting(false);
      handleErrorDailog();
    }
  };
  const handleSeach = async () => {
    if (serachText === "" || null) {
      window.location.reload(false);
    } else {
      setIsSearching(true);
      setIsSearchDataShow(true);
      const searchText = serachText.toLowerCase().replace(searchTextRegex, "");

      const resultBannerList = await Service.getSearchRes("banner", searchText);
      if (resultBannerList === "error") {
        setIsSearching(false);
        setOpenErrorDailog(true);
      } else {
        setBannerList(resultBannerList);
        setIsSearching(false);
      }
    }
  };

  const handleSuccessDailog = () => {
    setOpenFormDailog(false);
    setOpenAlertDailog(true);
  };
  const handleErrorDailog = () => {
    setOpenFormDailog(false);
    setOpenConfirmDailog(false);
    setOpenErrorDailog(true);
  };
  const closeFormDailog = () => {
    setOpenFormDailog(false);
  };
  const closeAlertDailog = () => {
    setOpenAlertDailog(false);
    window.location.reload(false);
  };
  const closeErrorDailog = () => {
    setOpenErrorDailog(false);
  };
  const closeConfirmDailog = () => {
    setOpenConfirmDailog(false);
  };
  const setCloseBtnAppear = () => {
    setIsCloseBtnAppear(false);
  };

  let count = 0;
  return isLoading ? (
    <div className="bannerlistpage">
      <i className="fas fa-spinner fa-pulse fa-2x "></i>
    </div>
  ) : (
    <div className="bannerlistpage">
      <div className="mainSection">
        <ConfirmDialogBox
          openDailog={openConfirmDailog}
          onSetOpenDailog={closeConfirmDailog}
          handleConfirmOkBtn={deleteData}
          isLoading={isDeleting}
          title="Delete"
          des={"Are you sure to delete " + selectedBannerName + " " + "details"}
        ></ConfirmDialogBox>
        <AlertDialogBox
          openDailog={openAlertDailog}
          onSetOpenDailog={closeAlertDailog}
          title="Added"
          des="New Banner has been added sccessfully"
        ></AlertDialogBox>
        <ErorrDialogBox
          openDailog={openErrorDailog}
          onSetOpenDailog={closeErrorDailog}
          title="Error"
          des="Someting went wrong. Try again"
        ></ErorrDialogBox>

        <FormPrompt
          openDailog={openFormDailog}
          title="Add New Banner"
          onSetOpenDailog={closeFormDailog}
          isCloseBtnAppear={isCloseBtnAppear}
        >
          <AddBanner
            setCloseBtnAppear={setCloseBtnAppear}
            handleSuccessDailog={handleSuccessDailog}
            handleErrorDailog={handleErrorDailog}
            collectionName="banner"
            id="bannerid"
          ></AddBanner>
        </FormPrompt>

        <div className="topheader">
          <ul>
            <li>
              <i
                className="fa fa-arrow-circle-o-right fa-2x"
                aria-hidden="true"
              ></i>
            </li>
            <li>
              <h5>Banner</h5>
            </li>
          </ul>
        </div>
        <hr />
        <div className="topSection">
          <div className="wrap">
            <ul>
              <li>
                <div className="search">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="Search Banner by  name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSeach();
                      }
                    }}
                    onChange={(e) => {
                      setSerachText(e.target.value);
                    }}
                  />

                  <button
                    onClick={handleSeach}
                    type="submit"
                    className="searchButton"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </li>
              <li style={{ fontSize: "12px" }}> #</li>
              <li style={{ fontSize: "12px" }}>{bannerList?.length} </li>
            </ul>
          </div>

          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              setOpenFormDailog(true);
            }}
          >
            + Add Banner
          </button>
        </div>
        <table className="table table-striped">
          <thead className="thead tablehead">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Banner Image</th>
              <th scope="col">Name</th>

              <th scope="col">Option</th>
            </tr>
          </thead>
          {isSearching ? (
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
          ) : bannerList?.length === 0 ? (
            <tbody>
              <tr>
                <td>No Banner Found</td>
              </tr>
            </tbody>
          ) : (
            <tbody className="tablebody">
              {bannerList &&
                bannerList.map((p) => {
                  count++;
                  let date = new Date(p.timeStamp.toDate());
                  const createdTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                  const createdDate = `${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()}`;

                  return (
                    <tr key={p?.bannerid}>
                      <td className="align-middle">{count}</td>
                      <td className="align-middle">
                        {p.imgaeurl === "" ? (
                          <div className="userbg">
                            <i className="fa fa-user fa-2x"></i>
                          </div>
                        ) : (
                          <img className="image" alt="" srcSet={p.imgaeurl} />
                        )}
                      </td>
                      <td className="align-middle">{p?.name}</td>

                      <td className="align-middle">
                        <Link to="editBanner">
                          <button
                            onClick={async () => {
                              const sendData = {
                                ...p,
                                collectionName: "banner",
                                bannerId: p?.bannerid,
                              };

                              props.setOnBannerDetails(sendData);
                            }}
                            type="button"
                            className="btn btn-success"
                          >
                            <i className="fa fa-edit" aria-hidden="true"></i>
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            handleOnDelete(p?.name, p?.bannerid);
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <div className="loadmoredatasection">
          {isLoadMoredata ? (
            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
          ) : (
            <div className="nomoredatatext">{noMoreDataText}</div>
          )}
          {bannerList?.length === 0 ? null : isSearchDataShow ? null : (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => showMore()}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnBannerDetails: (p) => {
      dispatch(setbanner(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(BannerList);
