import React, { useState, useEffect } from "react";

import Service from "../../Service/firebase";

import "./newuserform.css";
const NewUserForm = (props) => {
  const [salons, setSalons] = useState([]);
  const [salonLists, setSalonLists] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onFetchSalonType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onFetchSalonType = async () => {
    setIsLoadMoreData(true);

    const fetchedSalonDataList = await Service.getAllData("User");
    if (fetchedSalonDataList.length == 0) {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
      //console.log("hello yd 4");
      //console.log(salonLists);
    }
  };
  console.log(salons);
  // const { salonLists } = salons;
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="container">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Name</label>
            <input
              name="name"
              type="text"
              className="form-control"
              id="name"
              onChange={props.onEdit}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Email</label>
            <input
              name="email"
              type="text"
              className="form-control"
              id="email"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Phone</label>
            <input
              name="phone"
              type="text"
              className="form-control"
              id="phone"
              onChange={props.onEdit}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Password</label>
            <input
              name="password"
              type="text"
              className="form-control"
              id="password"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Status</label>
            <input
              name="status"
              type="text"
              className="form-control"
              id="status"
              onChange={props.onEdit}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Gender</label>
            <input
              name="gender"
              type="text"
              className="form-control"
              id="gender"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row second_section">
          <div className="clo-sm-6">
            <div className="profileimage">{props.htmlelement}</div>
          </div>

          <div className="col-sm-6 btn_section">
            <label htmlFor="files" className="selectimage">
              Upload Image
            </label>
            <input
              name="avatarimage"
              id="files"
              type="file"
              onChange={props.onImageChange}
            />
            <button
              type="button"
              className="removebutton"
              onClick={props.onImageRemove}
            >
              {" "}
              Remove
            </button>
          </div>
        </div>
      </div>
      <button className="btn btn-success savebtn" type="submit">
        Save
      </button>
    </form>
  );
};

export default NewUserForm;
