import firebase from "../firebase";
import { startOfDay, endOfDay } from "date-fns";

class Service {
  async addData(sendData, collectionName, idName) {
    //console.log(sendData);
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .add(sendData)
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        await db
          .collection(collectionName)
          .doc(docRef.id)
          .update({
            [idName]: docRef.id,
          })
          .then(() => {
            console.log("Data hass been added successfully");
          })
          .catch((error) => {
            console.error("Error during updateing document id: ", error);
          });
        return "success";
      })
      .catch((error) => {
        console.error("Error during adding document: ", error);
        return "error";
      });
    return res;
  }
  async addDataCategory(sendData, collectionName, idName) {
    console.log(sendData.salonTypeId);
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .add(sendData)
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        await db
          .collection(collectionName)
          .doc(docRef.id)
          .update({
            [idName]: docRef.id,
          })
          .then(() => {
            console.log("Data hass been added successfully");
          })
          .catch((error) => {
            console.error("Error during updateing document id: ", error);
          });
        return "success";
      })
      .catch((error) => {
        console.error("Error during adding document: ", error);
        return "error";
      });
    return res;
  }
  async addDataSubCategory(sendData, collectionName, idName) {
    //console.log(sendData);
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .add(sendData)
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        await db
          .collection(collectionName)
          .doc(docRef.id)
          .update({
            [idName]: docRef.id,
          })
          .then(() => {
            console.log("Data hass been added successfully");
          })
          .catch((error) => {
            console.error("Error during updateing document id: ", error);
          });
        return "success";
      })
      .catch((error) => {
        console.error("Error during adding document: ", error);
        return "error";
      });
    return res;
  }
  async addDataSalontype(sendData, collectionName, idName) {
    //console.log(sendData);
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .add(sendData)
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        await db
          .collection(collectionName)
          .doc(docRef.id)
          .update({
            [idName]: docRef.id,
          })
          .then(() => {
            console.log("Data hass been added successfully");
          })
          .catch((error) => {
            console.error("Error during updateing document id: ", error);
          });
        return "success";
      })
      .catch((error) => {
        console.error("Error during adding document: ", error);
        return "error";
      });
    return res;
  }
  async addDataAndReturnId(sendData, collectionName, idName) {
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .add(sendData)
      .then(async (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        await db
          .collection(collectionName)
          .doc(docRef.id)
          .update({
            [idName]: docRef.id,
          })
          .then(() => {
            console.log("Data hass been added successfully");
          })
          .catch((error) => {
            console.error("Error during updateing document id: ", error);
          });
        return docRef.id;
      })
      .catch((error) => {
        console.error("Error during adding document: ", error);
        return "error";
      });
    return res;
  }
  async deleteData(collectionName, docID) {
    const db = firebase.firestore();
    const res = await db
      .collection(collectionName)
      .doc(docID)
      .delete()
      .then(() => {
        console.log("Document successfully deleted");
        return "success";
      })
      .catch((error) => {
        console.error("Error during removing document: ", error);
        return "error";
      });
    return res;
  }
  async updateData(sendData, collectionName, docID) {
    const db = firebase.firestore();

    const res = await db
      .collection(collectionName)
      .doc(docID)
      .update(sendData)
      .then(() => {
        console.log("Document Updated: ");
        return "success";
      })
      .catch((error) => {
        console.error("Error during update document: ", error);
        return "error";
      });
    return res;
  }
  async uploadImage(image) {
    const storage = firebase.storage();
    await storage.ref(`images/${image.name}`).put(image);

    const url = await storage
      .ref("images")
      .child(image.name)
      .getDownloadURL()
      .then((url) => {
        return url;
      });

    return url;
  }
  async getJoinData(collectionName, limit, secondtable) {
    // const db = firebase.firestore();
    //let loadedPosts = {};
    var emp = [];
    //const fetchedDataList = collection(collectionName)
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)
      //  .orderBy('timestamp', 'desc')
      .limit(limit)
      .get()
      .then((docSnaps) => {
        let fetchedDataList = [];

        //console.log(docSnaps.docs);
        let count = 0;
        docSnaps.docs.forEach((doc) => {
          count++;
          //  console.log(doc.data());
          fetchedDataList.push(doc.data());

          // fetchedDataList['d'] = doc.data().salontypeid;
          let sData = this.getIdByName(secondtable, doc.data().salonTypeId);
          console.log(sData);
          sData.then(function (result) {
            //result[0].name
          });
        });

        console.log(emp);
        for (var i = 0; i < emp.length; i++) {
          console.log(emp[i]);

          // fetchedDataList.push({'salonName': emp[i]});
        }

        console.log(fetchedDataList);
        console.log("Data fetched ");

        return fetchedDataList;
      })
      .catch((e) => {
        const fetchedDataList = [];
        console.log("Error during fetching data" + e);
        return fetchedDataList;
      });
    return fetchedDataList;
  }
  async getData(collectionName, limit) {
    console.log(collectionName);
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)
      .limit(limit)
      .get()
      .then((snapshot) => {
        let fetchedDataList = [];

        snapshot.docs.forEach((doc) => {
          fetchedDataList.push(doc.data());
        });
        console.log("Data fetched ");

        return fetchedDataList;
      })
      .catch((e) => {
        const fetchedDataList = [];
        console.log("Error during fetching data" + e);
        return fetchedDataList;
      });
    return fetchedDataList;
  }

  async getDataWithStatus(collectionName, limit) {
    const db = firebase.firestore();
    //     firebase.firestore().collection('Partners')
    //      .where("Email",'==',this.state.currentUser).get().then( querySnapshot => {
    //        querySnapshot.forEach(doc => {

    //          this.setState({
    //            Theatre:doc.data().Theatre
    //          })

    //      })
    //  })
    const fetchedDataList = await db
      .collection(collectionName)

      .where("status", "false")
      .orderBy("timeStamp", "desc")
      .limit(limit)

      .get()
      .then((snapshot) => {
        let fetchedDataList = [];

        snapshot.docs.forEach((doc) => {
          fetchedDataList.push(doc.data());
        });
        console.log("Data fetched ");

        return fetchedDataList;
      })
      .catch((e) => {
        const fetchedDataList = [];
        console.log("Error during fetching data" + e);
        return fetchedDataList;
      });
    return fetchedDataList;
  }
  async getIdByName(collectionName, docID) {
    // console.log("collection name: "+ collectionName);
    // console.log("doc id: "+ docID);
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)
      .doc(docID)
      .get()
      .then((snapshot) => {
        let fetchedDataList = [];
        fetchedDataList.push(snapshot.data());
        //  console.log("Data fetched ID wise "+ snapshot.data());
        //  fetchedDataList.push(snapshot.data().name);
        // console.log("Data fetched ID wise "+ snapshot.data().name);
        // snapshot.docs.forEach((doc) => {
        //   console.log("Data fetched ID wise 1"+ doc);
        //   fetchedDataList.push(doc.data());
        // });
        // console.log("Data fetched ID wise ");

        return fetchedDataList;
      })
      .catch((e) => {
        const fetchedDataList = [];
        console.log("Error during fetching data" + e);
        return fetchedDataList;
      });
    return fetchedDataList;
  }
  async getAllData(collectionName) {
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)

      .get()
      .then((snapshot) => {
        let fetchedDataList = [];

        snapshot.docs.forEach((doc) => {
          fetchedDataList.push(doc.data());
        });
        console.log("Data fetched ");

        return fetchedDataList;
      })
      .catch((e) => {
        const fetchedDataList = [];
        console.log("Error during fetching data" + e);
        return fetchedDataList;
      });
    return fetchedDataList;
  }
  async getDataWithoutLimit(collectionName, docID) {
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)
      .doc(docID)
      .get()
      .then((doc) => {
        console.log("Data fetched ");

        return doc.data();
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return "error";
      });
    return fetchedDataList;
  }
  async getEmployeeList(collectionName) {
    const db = firebase.firestore();
    const fetchedDataList = await db
      .collection(collectionName)
      .orderBy("timeStamp", "desc")
      .get()
      .then((snapshot) => {
        console.log(collectionName);
        const fetchedDataList = [];

        snapshot.docs.forEach((doc) => {
          fetchedDataList.push({
            firstname: doc.data().firstname,
            lastname: doc.data().lastname,
            doctorid: doc.data().doctorid,
          });
        });
        return fetchedDataList;
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return "error";
      });
    return fetchedDataList;
  }
  async getDocCount() {
    let getAllDocCount = {
      salontype: 0,
      category: 0,
      subcategory: 0,
      users: 0,
      vendors: 0,
      receptionist: 0,
      operationreport: 0,
      birthreport: 0,
      deathreport: 0,
      payrolllist: 0,
    };
    const allCollectionName = [
      "salonType",
      "category",
      "subCategory",
      "users",
      "vendors",
      "laboratorist",
      "nurses",
      "operationreport",
      "payrolllist",
      "pharmacists",
      "receptionist",
    ];
    const db = firebase.firestore();
    await Promise.all(
      allCollectionName.map(async (p) => {
        await db
          .collection(p)
          .get()
          .then((snapshot) => {
            getAllDocCount = {
              ...getAllDocCount,
              [p]: snapshot.docs.length,
            };
          })
          .catch((e) => {
            console.log("Error during fetching doc count" + e);
          });
      })
    );

    return getAllDocCount;
  }
  async getSearchRes(collectionName, searchText) {
    const db = firebase.firestore();
    const res = db
      .collection(collectionName)
      .orderBy("searchbyname")
      .startAt(searchText)
      .endAt(searchText + "\uf8ff")
      .get()
      .then((snapshot) => {
        const resultPatientlist = [];

        snapshot.docs.forEach((doc) => {
          console.log(doc.data());
          resultPatientlist.push(doc.data());
        });
        return resultPatientlist;
      })
      .catch((e) => {
        console.log("Error dunring searching in firebase" + e);
        return "error";
      });
    return res;
  }
  async getTotalNumOfPerson(collectionName) {
    const db = firebase.firestore();
    const res = db
      .collection(collectionName)
      .get()
      .then((snapshot) => {
        return snapshot.docs.length;
      })
      .catch((e) => {
        console.log("Error during getTotalNumOfPerson" + e);
        return "error";
      });
    return res;
  }

  async getAllCompletedBookingCount(collectionName) {
    const db = firebase.firestore();
    const count = await db
      .collection("appointments")
      .where("status", "==", "Completed")
      .get()
      .then((snapshot) => {
        return snapshot.docs.length;
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return 0;
      });
    return count;
  }

  async getTodayBookingCount() {
    const today = new Date();

    const db = firebase.firestore();
    const count = await db
      .collection("appointments")
      .where("record_timestamp", "==", today)
      .get()
      .then((snapshot) => {
        return snapshot.docs.length;
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return 0;
      });

    return count;
  }

  async getBookingsCountWithinDateRangeAndStatus(
    startDateTime,
    endDateTime,
    status
  ) {
    startDateTime = startOfDay(startDateTime);
    endDateTime = endOfDay(endDateTime);

    return await firebase
      .firestore()
      .collection("appointments")
      .where("record_timestamp", ">", startDateTime)
      .where("record_timestamp", "<", endDateTime)
      .where("status", "==", status)
      .get()
      .then((snapshot) => {
        const recordsCount = snapshot.docs.length;
        console.log(snapshot.docs.length);

        return recordsCount;
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return 0;
      });
  }

  async getBookingsDataWithinDateRange(
    startDateTime,
    endDateTime,
    status,
    limit
  ) {
    startDateTime = startOfDay(startDateTime);
    endDateTime = endOfDay(endDateTime);

    let query = await firebase
      .firestore()
      .collection("appointments")
      .where("record_timestamp", ">", startDateTime)
      .where("record_timestamp", "<", endDateTime);

    if (status != "All") {
      query = await query.where("status", "==", status);
    }
    return await query
      .orderBy("record_timestamp", "asc")
      .limit(limit)
      .get()
      .then((snapshot) => {
        const list = [];
        snapshot.docs.forEach((doc) => {
          list.push(doc.data());
        });
        return list;
      })
      .catch((e) => {
        console.log("Error during fetching data" + e);
        return [];
      });
  }
}

export default new Service();
