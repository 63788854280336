import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
  personDetailsReducer,
  reportDetailsReducer,
} from "./petientdetailsReducer";
import { loginDetailsReducer } from "./loginDeyailsReducer";

import storage from "redux-persist/lib/storage";
import { userReducer } from "./userReducer";
import { categoryReducer } from "./categoryReducer";
import { salontypeReducer } from "./salontypeReducer";
import { subcategoryReducer } from "./subcategoryReducer";
import { bannerReducer } from "./bannerReducer";
import { vendorReducer } from "./vendorReducer";

const rootReducer = combineReducers({
  personDetails: personDetailsReducer,
  user:userReducer,
  category:categoryReducer,
  reportDetails: reportDetailsReducer,
  loginDetails: loginDetailsReducer,
  salontype:salontypeReducer,
  subcategory:subcategoryReducer,
  banner:bannerReducer,
  vendor:vendorReducer,
});

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["personDetails", "reportDetails"],
};

export default persistReducer(persistConfig, rootReducer);
