import React, { useState, useEffect } from "react";

import Service from "../../Service/firebase";

import "./newvendorform.css";
const NewVendorForm = (props) => {
  const [salons, setSalons] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreData, setIsLoadMoreData] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      onFetchSalonType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onFetchSalonType = async () => {
    setIsLoadMoreData(true);

    const fetchedSalonDataList = await Service.getAllData("vendor");
    console.log(fetchedSalonDataList.length);
    if (fetchedSalonDataList.length == 0) {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    } else {
      setSalons(fetchedSalonDataList);
      setIsLoadMoreData(false);
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="container">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Name</label>
            <input
              name="name"
              type="text"
              className="form-control"
              id="name"
              onChange={props.onEdit}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <label htmlFor="validationDefault01">Salon Type</label>
            <select
              name="Vendorid"
              id="Vendorid"
              className="form-control"
              onChange={props.onEdit}
            >
              <option value="">Select Salon type</option>

              {salons.map((option) => (
                <option value={option.Vendorid}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row second_section">
          <div className="clo-sm-6">
            <div className="profileimage">{props.htmlelement}</div>
          </div>
          <div className="col-sm-6 btn_section">
            <label htmlFor="files" className="selectimage">
              Upload Image
            </label>
            <input
              name="avatarimage"
              id="files"
              type="file"
              onChange={props.onImageChange}
            />
            <button
              type="button"
              className="removebutton"
              onClick={props.onImageRemove}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row second_section">
          <div className="clo-sm-6">
            <div className="profileimage">{props.htmlelement}</div>
          </div>
          <div className="col-sm-6 btn_section">
            <label htmlFor="files" className="selectimage">
              Salon Upload Image
            </label>
            <input
              name="salon_image"
              id="files"
              type="file"
              onChange={props.onSalonImageChange}
            />
            <button
              type="button"
              className="removebutton"
              onClick={props.onSalonImageRemove}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <button className="btn btn-success savebtn" type="submit">
        Save
      </button>
    </form>
  );
};

export default NewVendorForm;
